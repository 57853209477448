import startCase from "lodash/startCase";
import React, { createRef } from "react";
import { Link } from "react-router-dom";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  List,
  Segment,
} from "semantic-ui-react";

import tablesSettings from "constants/Table";
import ACL_RELATIONSHIPS from "acl-relationships";
import BaseTable from "components/BaseTable";
import CustomDropdown from "components/CustomDropdown";
import TagList from "components/TagList";
import { checkIsAuthorized, formatDate } from "components/helpers";
import withRoleCheck from "components/hocs/withRoleCheck";
import { CASE_TIERS } from "constants/Case";
import setPageTitle from "helpers/title";
import AuthService from "services/Auth";
import CaseService from "services/Case";
import EntityService from "services/Entity";
import RegionService from "services/Regions";
import SourceStatusService from "services/SourceStatus";
import UserService from "services/User";
import "styles/campaign.scss";
import "styles/table.scss";
import _AssignInvestigatorsModal from "./AssignInvestigatorsModal";
import _CaseFormModal from "./CaseFormModal";
import CampaignStatusModal from "../campaign/components/CampaignStatusModal";
import * as PropTypes from "prop-types";
import ConfirmationModal from "components/modals/ConfirmationModal";
import { UpdateCampaignCaseModal } from "./UpdateCampaignCaseModal";
import CampaignService from "services/Campaign";
import { getCountryByCode } from "helpers/countries";

const AssignInvestigatorsModal = withRoleCheck(_AssignInvestigatorsModal, [
  ACL_RELATIONSHIPS.case.edit,
]);

const CaseFormModal = withRoleCheck(_CaseFormModal, [
  ACL_RELATIONSHIPS.case.edit,
]);
const AddCaseToCampaignButton = withRoleCheck(Button, [
  // ACL_RELATIONSHIPS.adminUser.create,
]);
const AddCaseToCampaignDropdownItem = withRoleCheck(Dropdown.Item, [
  // ACL_RELATIONSHIPS.adminUser.create,
]);
const UpdateCampaignCaseAssigneeDropdownItem = withRoleCheck(Dropdown.Item, [
  // ACL_RELATIONSHIPS.adminUser.create,
]);

UpdateCampaignCaseModal.propTypes = { onSuccess: PropTypes.func };

class CasesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CaseService.getTable;
    this.addCampaignCaseModal = createRef();
    this.addSingleCampaignCaseModal = createRef();
    this.updateAssigneeModal = createRef();

    this.state = {
      ...this.state,
      header: "Cases",
      headerIcon: "folder open",
      defaultFilterParams: {
        campaign_id: this.props.campaignId,
        entity_id: this.props.entityId,
        in_campaign: !!this.props.campaignId,
      },
      className: "Case",
      tableName: "cases",
      noDataText: "No Cases found. Try adjusting your filters.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.caseTags.create,
        ACL_RELATIONSHIPS.caseTags.read,
        ACL_RELATIONSHIPS.caseTags.delete,
      ]),
      users: [],
      investigationStatuses: [],
      regions: [],
      countries: [],
      sourceStatuses: [],
      enableSearch: true,
      enableCustomFieldsSettings: true,
      exportTableName: "CasesTable",
      createButton: this.props.campaignId && (
        <AddCaseToCampaignButton
          ref={this.addCampaignCaseModal}
          size="tiny"
          content="Add Case"
          showCaseSelector
          className="item-adder"
          onClick={() => this.addCampaignCaseModal.current.open()}
        />
      ),
    };
  }

  removeFromCampaign = async ids => {
    await CaseService.removeCaseFromCampaign({
      case_ids: ids,
      campaign_id: parseInt(this.props.campaignId),
    });
    this.fetchData();
  };

  checkCaseEntityValidity = checkedIds => {
    const validCases = [];
    const invalidCases = [];

    this.state.rows.forEach(row => {
      const isEntityValid = row.entity_id && row.entity_id !== 0;
      const isSelected = checkedIds.includes(row.id);
      if (isSelected) {
        isEntityValid
          ? validCases.push(row.id)
          : invalidCases.push({
              id: row.id,
              name: row.name,
              case_id: row.case_id,
            });
      }
    });

    return { caseIds: validCases, invalidCases: invalidCases };
  };

  checkSelectedRowsInCampaign = () => {
    const { checkedArr, rows } = this.state;
    const { campaignId } = this.props;
    let inCampaign = true;
    let notInCampaign = true;

    checkedArr.forEach(id => {
      const row = rows.find(row => row.id === id);
      const inThisCampaign = row.campaign_cases.some(
        campaignCase => campaignCase.campaign_id === parseInt(campaignId)
      );

      if (!inThisCampaign) {
        inCampaign = false;
      } else {
        notInCampaign = false;
      }
    });

    return { inCampaign, notInCampaign };
  };

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    const { inCampaign, notInCampaign } = this.checkSelectedRowsInCampaign();
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={disableActions}
      >
        <Dropdown.Menu direction="left">
          <AssignInvestigatorsModal
            caseIds={this.state.checkedArr}
            onSuccess={this.fetchData}
          />
          {notInCampaign && (
            <AddCaseToCampaignDropdownItem
              content="Add To Campaign"
              onClick={() =>
                this.addCampaignCaseModal.current.open(
                  this.checkCaseEntityValidity(this.state.checkedArr)
                )
              }
            />
          )}
          {this.props.campaignId && inCampaign && (
            <UpdateCampaignCaseAssigneeDropdownItem
              content="Update Campaign Assignee"
              onClick={() =>
                this.updateAssigneeModal.current.open(
                  this.checkCaseEntityValidity(this.state.checkedArr)
                )
              }
            />
          )}
          {this.props.campaignId && inCampaign && (
            <ConfirmationModal
              menuTrigger
              actionDescription="Remove From Campaign"
              onConfirm={() => this.removeFromCampaign(this.state.checkedArr)}
              popupPosition="right"
              warning
            >
              <p>Are you sure you want to remove this Case?</p>
            </ConfirmationModal>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  fetchInvestigationStatuses = async () => {
    const investigationStatuses =
      await CaseService.getInvestigationStatusesForFilters();
    investigationStatuses.unshift({ id: "None", name: "None" });
    this.setState({ investigationStatuses }, this.updateFilterOptions);
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(({ id, name, countries }) => ({
      id,
      name,
      extraData: { countries },
    }));

    const allCountries = rawRegions.reduce((countries, region) => {
      countries.push(...region.countries);
      return countries;
    }, []);
    allCountries.sort();
    const countries = Array.from(new Set(allCountries)).map(country => ({
      id: country,
      name: getCountryByCode(country).text,
      value: country,
      key: country,
    }));
    this.setState({ regions, countries }, this.updateFilterOptions);
  };

  fetchSourceStatuses = async () => {
    const sourceStatuses = await SourceStatusService.getForFilters();
    this.setState({ sourceStatuses }, this.updateFilterOptions);
  };

  checkCampaignCaseExists(original) {
    const matchCampaignId = c =>
      c.campaign_id === parseInt(this.props.campaignId);
    const campaignCase = original.campaign_cases.find(matchCampaignId);
    return !!campaignCase;
  }

  async componentDidMount() {
    setPageTitle("Cases");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchInvestigationStatuses();
      this.fetchUsers();
      this.fetchTags();
      this.fetchRegions();
      this.fetchSourceStatuses();
      this.fetchCustomFields();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "Case ID",
        accessor: "id",
        width: 50,
        Cell: ({ original: { id }, value }) => (
          <Link to={"/cases/" + id}>{value}</Link>
        ),
      },
      {
        Header: "Case #",
        accessor: "case_id",
        width: 50,
        Cell: ({ original: { id }, value }) => (
          <Link to={"/cases/" + id}>{value}</Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => (
          <Link to={"/cases/" + props.original.id}>{props.value}</Link>
        ),
      },
      {
        Header: "Organization",
        accessor: "organization",
      },
      {
        Header: "Entity",
        accessor: "entity.name",
        Cell: props => (
          <Link to={"/entities/" + props.original.entity_id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Campaigns",
        accessor: "campaign_cases",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {value.map(({ campaign_id, campaign_name }) => (
              <List.Item>
                <Link to={`/campaigns/${campaign_id}`}>{campaign_name}</Link>
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "# Total Machines",
        accessor: "total_num_machines",
      },
      {
        Header: "# Recent Machines",
        accessor: "recent_num_machines",
      },
      {
        Header: "# Approved Machines",
        accessor: "approved_num_machines",
      },
      {
        Header: "First Event",
        accessor: "first_event",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Last Event",
        accessor: "last_event",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Case Tier",
        accessor: "case_tier",
        Cell: ({ value }) =>
          value === CASE_TIERS.UNUSABLE ? "Unusable" : value,
      },
      {
        Header: "Case Investigators",
        accessor: "case_investigators",
        Cell: ({ value }) => value.map(ci => ci.name).join(", "),
      },
      {
        Header: "Investigation Status",
        accessor: "investigation_status.name",
      },
      {
        Header: "Investigation Status Last Updated At",
        accessor: "investigation_status_last_updated_at",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Data Score",
        accessor: "data_score",
      },
      {
        Header: "Website",
        accessor: "website",
      },
      {
        Header: "Industry",
        accessor: "industry",
      },
      {
        Header: "Actionable Domain",
        accessor: "actionable_domain",
      },
      {
        Header: "Region",
        accessor: "region_name",
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ value }) => getCountryByCode(value)?.text,
      },
      {
        Header: "Source Status",
        accessor: "source_status_name",
      },
      {
        Header: "Created",
        accessor: "created_at",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Updated",
        accessor: "updated_at",
        Cell: ({ value }) => formatDate(value),
      },

      ...(this.props.campaignId ? this.getCampaignColumns() : []),
      {
        Header: "Actions",
        id: "actions",
        accessor: "id",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ value, original }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <CaseFormModal caseId={value} fetchData={this.fetchData} />
              {(!this.props.campaignId ||
                !this.checkCampaignCaseExists(original)) &&
                original.entity_id && (
                  <AddCaseToCampaignDropdownItem
                    content="Add To Campaign"
                    icon="plus"
                    onClick={() => {
                      if (this.addSingleCampaignCaseModal.current) {
                        return this.addSingleCampaignCaseModal.current.open(
                          this.checkCaseEntityValidity([value])
                        );
                      } else {
                        return this.addCampaignCaseModal.current.open(
                          this.checkCaseEntityValidity([value])
                        );
                      }
                    }}
                  />
                )}

              {this.props.campaignId && this.checkCampaignCaseExists(original) && (
                <ConfirmationModal
                  menuTrigger
                  icon="trash"
                  actionDescription="Remove From Campaign"
                  onConfirm={() => this.removeFromCampaign([value])}
                  popupPosition="right"
                  warning
                >
                  <p>Are you sure you want to remove this Case?</p>
                </ConfirmationModal>
              )}
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  getCampaignColumns() {
    const findCampaignCase = original => {
      const matchCampaignId = c =>
        c.campaign_id === parseInt(this.props.campaignId);
      return original.campaign_cases.find(matchCampaignId);
    };

    return [
      {
        Header: "Campaign Status",
        accessor: "campaign_status",
        minWidth: tablesSettings.statusColumnMinWidth,
        className: "centered",
        Cell: ({ original }) => {
          const campaignCase = findCampaignCase(original);
          return campaignCase ? (
            <CampaignStatusModal
              campaignStatus={
                campaignCase?.campaign_status || { id: 0, name: "" }
              }
              campaignId={parseInt(this.props.campaignId)}
              caseId={original.id}
              updateData={this.fetchData}
              effectiveDate={campaignCase?.effective_date}
            />
          ) : (
            "N/A"
          );
        },
      },

      {
        Header: "Effective Date",
        accessor: "campaign_status_effective_date",
        Cell: ({ original }) => {
          const campaignCase = findCampaignCase(original);
          return campaignCase ? campaignCase.effective_date : "N/A";
        },
      },
      {
        Header: "Campaign Assignee",
        accessor: "campaign_assignee",
        Cell: ({ original }) => {
          const campaignCase = findCampaignCase(original);
          return campaignCase ? campaignCase.assigned_user_name : "N/A";
        },
      },
    ];
  }

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));
    users.unshift({ id: "None", name: "None" });
    this.setState({ users }, this.updateFilterOptions);
  };

  fetchCustomFields = async () => {
    const customFields = await CaseService.getCustomFields();
    const entitiesCustomFields = await EntityService.getCustomFields();
    this.setState(
      {
        customFields: [
          { accessorPrefix: "custom_fields", fields: customFields },
          {
            accessorPrefix: "entity_custom_fields",
            headerPrefix: "Entity",
            fields: entitiesCustomFields,
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  updateFilterOptions = async () => {
    const {
      users,
      investigationStatuses,
      tags,
      regions,
      countries,
      sourceStatuses,
      customFields,
    } = this.state;

    let filters = [
      {
        key: "first_event",
        title: "First Event Date",
        type: "dateRange",
      },
      {
        key: "last_event",
        title: "Last Event Date",
        type: "dateRange",
      },
      {
        key: "effective_date",
        title: "Effective Date",
        type: "dateRange",
        hidden: !this.props.campaignId,
      },
      {
        key: "case_tier",
        title: "Case Tier",
        type: "select",
        data: Object.keys(CASE_TIERS).map(key => {
          return {
            id: CASE_TIERS[key],
            name: startCase(key.toLowerCase()),
          };
        }),
      },
      {
        key: "campaign_id",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: filters => {
          filters["has_case"] = true;
          return CampaignService.getCampaignsForFilters(filters);
        },
        props: {
          includeNoneOption: true,
        },
        hidden: this.props.campaignId,
      },
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: filters => {
          filters["has_case"] = true;
          return EntityService.getEntitiesForFilters(filters);
        },
        hidden: this.props.entityId,
      },
      {
        key: "case_id",
        title: "Case",
        type: "ruvixxSelect",
        queryFn: filters => {
          if (this.props.entityId) {
            filters["entity_id"] = this.props.entityId;
          }
          return CaseService.getForFilters(filters);
        },
      },
      {
        key: "search:domains",
        title: "Domains",
        type: "input",
      },
      {
        key: "investigation_status_id",
        title: "Investigation Status",
        type: "select",
        data: investigationStatuses,
      },
      {
        key: "case_investigators",
        title: "Case Investigators",
        type: "select",
        data: users,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "total_num_machines",
        title: "# Total Machines",
        type: "range",
      },
      {
        key: "recent_num_machines",
        title: "# Recent Machines",
        type: "range",
      },
      {
        key: "approved_num_machines",
        title: "# Approved Machines",
        type: "range",
      },
      {
        key: "data_score",
        title: "Data Score",
        type: "range",
      },
      {
        key: "search:actionable_domain",
        title: "Actionable Domain",
        type: "input",
      },
      {
        key: "regions",
        title: "Region",
        type: "select",
        data: regions,
      },
      {
        key: "countries",
        title: "Country",
        type: "select",
        data: countries,
      },
      {
        key: "source_statuses",
        title: "Source Status",
        type: "select",
        data: sourceStatuses,
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${field}`,
          title: field,
          type: "input",
        });
      })
    );
    this.setState({ filters });
  };

  render() {
    if (this.props.campaignId) {
      return (
        <>
          <UpdateCampaignCaseModal
            ref={this.addCampaignCaseModal}
            modalHeader={"Add Case to Campaign"}
            showCaseSelector
            campaignId={this.props.campaignId}
            entityId={this.props.entityId}
            onSuccess={this.fetchData}
          />

          <UpdateCampaignCaseModal
            ref={this.addSingleCampaignCaseModal}
            modalHeader={"Add Case to Campaign"}
            campaignId={this.props.campaignId}
            entityId={this.props.entityId}
            onSuccess={this.fetchData}
          />

          <UpdateCampaignCaseModal
            filterCampaigns
            filterEntities
            ref={this.updateAssigneeModal}
            modalHeader={"Update Campaign Assignee"}
            campaignId={this.props.campaignId}
            entityId={this.props.entityId}
            onSuccess={this.fetchData}
          />
          {this.renderTable()}
        </>
      );
    } else {
      return (
        <Container fluid className="route">
          <UpdateCampaignCaseModal
            filterCampaigns
            filterEntities
            ref={this.addCampaignCaseModal}
            modalHeader={"Add Case to Campaign"}
            campaignId={this.props.campaignId}
            entityId={this.props.entityId}
            onSuccess={this.fetchData}
          />
          <div className="campaign-segment">
            <Segment>{this.renderTable()}</Segment>
          </div>
        </Container>
      );
    }
  }
}

export default CasesTable;
