import React, { useEffect, useState } from "react";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import { Button, Dropdown, Header, Icon, Modal } from "semantic-ui-react";
import RevenueOpportunityForm from "../forms/RevenueOpportunityForm";
import CustomFieldService from "../../services/CustomField";

export default function RevenueOpportunityModal({
  revenueOpportunity,
  tableView,
  tableDropdown,
  sidebarView,
  entityId,
  caseId,
  campaignId,
  onSuccess,
  noIcon = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [customFieldConfigs, setCustomFieldConfigs] = useState([]);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const isValidRevenueOpportunity = revenueOpportunity => {
    return isObject(revenueOpportunity) && isNumber(revenueOpportunity.id);
  };

  const onClose = () => {
    onSuccess();
    setModalOpen(false);
  };

  useEffect(() => {
    fetchCustomFieldConfigs();
  }, []);

  const fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel(
        "RevenueOpportunity"
      );
    setCustomFieldConfigs(customFieldConfigs);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      size="large"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        tableView ? (
          <>
            {isValidRevenueOpportunity(revenueOpportunity) ? (
              <strong style={{ color: "black" }}>
                {revenueOpportunity.name}
              </strong>
            ) : (
              <span style={{ color: "grey" }}>(unset)</span>
            )}
            <Button
              size="mini"
              icon="caret down"
              className="standard"
              style={{ marginLeft: "0.5em" }}
              onClick={handleOpen}
            />
          </>
        ) : isValidRevenueOpportunity(revenueOpportunity) && !sidebarView ? (
          <Dropdown.Item
            onClick={handleOpen}
            content="Edit Revenue Opportunity"
            icon={noIcon ? null : "edit"}
          />
        ) : tableDropdown ? (
          <Dropdown.Item
            onClick={handleOpen}
            content="New Revenue Opportunity"
            icon={noIcon ? null : "plus"}
          />
        ) : sidebarView ? (
          <Icon
            onClick={handleOpen}
            name="pencil"
            style={{
              cursor: "pointer",
              verticalAlign: "-.5em",
              background: "none",
              color: "#333",
            }}
          />
        ) : (
          <Button
            content="New Opportunity"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        )
      }
    >
      <Header
        content={
          revenueOpportunity && isValidRevenueOpportunity(revenueOpportunity)
            ? "Edit Revenue Opportunity"
            : "Create Revenue Opportunity"
        }
      />
      <Modal.Content>
        {
          <RevenueOpportunityForm
            entityId={entityId ?? revenueOpportunity?.entity_id}
            campaignId={campaignId ?? revenueOpportunity?.campaign_id}
            caseId={caseId ?? revenueOpportunity?.case_id}
            revenueOpportunity={revenueOpportunity}
            onSuccess={onClose}
            customFieldConfigs={customFieldConfigs}
            setCustomFieldConfigs={setCustomFieldConfigs}
          />
        }
      </Modal.Content>
    </Modal>
  );
}
