/**
 * Defines the relationship between front-end and back-end paths to be used by
 * permission checks
 */

const ACL_RELATIONSHIPS = {
  activity: {
    read: {
      method: "get",
      path: "/contact/activity/*",
    },
    create: {
      method: "post",
      path: "/contact/activity",
    },
    edit: {
      method: "put",
      path: "/contact/activity/*",
    },
    delete: {
      method: "delete",
      path: "/contact/activity/*",
    },
  },
  activityCategory: {
    edit: {
      method: "put",
      path: "/activity/category/*",
    },
    delete: {
      method: "delete",
      path: "/activity/category/*",
    },
    read: {
      method: "get",
      path: "/activity/category/*",
    },
  },
  adminRole: {
    create: {
      method: "post",
      path: "/admin/role",
    },
    edit: {
      method: "put",
      path: "/admin/role/*",
    },
    delete: {
      method: "delete",
      path: "/admin/role/*",
    },
    read: {
      method: "get",
      path: "/admin/role",
    },
  },
  adminGroup: {
    create: {
      method: "post",
      path: "/group",
    },
    edit: {
      method: "put",
      path: "/group/*",
    },
    delete: {
      method: "delete",
      path: "/group/*",
    },
    read: {
      method: "get",
      path: "/group",
    },
  },
  adminRoleForFilters: {
    read: {
      method: "get",
      path: "/admin/role/for_filters",
    },
  },
  adminRolePermission: {
    edit: {
      method: "put",
      path: "/admin/role/*/permission/*",
    },
    delete: {
      method: "delete",
      path: "/admin/role/*/permission/*",
    },
    read: {
      method: "get",
      path: "/admin/role/*/permission/*",
    },
  },
  adminUser: {
    create: {
      method: "post",
      path: "/admin/user",
    },
    edit: {
      method: "put",
      path: "/admin/user/*",
    },
    delete: {
      method: "delete",
      path: "/admin/user/*",
    },
    read: {
      method: "get",
      path: "/admin/user",
    },
  },
  adminUserResetPassword: {
    create: {
      method: "post",
      path: "/admin/user/*/reset_password",
    },
  },
  attachmentUploadS3: {
    create: {
      method: "post",
      path: "/attachment/upload/s3",
    },
  },
  attachment: {
    delete: {
      method: "delete",
      path: "/attachment/*",
    },
  },
  auditLog: {
    read: {
      method: "get",
      path: "/audit_log/*/*",
    },
    events: {
      method: "get",
      path: "/audit_log/events",
    },
  },
  authLogin: {
    create: {
      method: "post",
      path: "/auth/login",
    },
  },
  callQueueAddContact: {
    create: {
      method: "post",
      path: "/call_queue/add_contact",
    },
  },
  callQueueItems: {
    read: {
      method: "get",
      path: "/call_queue/items",
    },
  },
  callQueueItemsWithAgentAssigned: {
    read: {
      method: "get",
      path: "/call_queue/items_with_agent_assigned",
    },
  },
  callQueueNextContact: {
    read: {
      method: "get",
      path: "/call_queue/next_contact",
    },
  },
  callQueueSkipContact: {
    create: {
      method: "post",
      path: "/call_queue/skip_contact",
    },
  },
  callQueue: {
    read: {
      method: "get",
      path: "/call_queue/*",
    },
  },
  callbackCustomForm: {
    create: {
      method: "post",
      path: "/callback/custom_form/",
    },
  },
  callbackCustomFormWrongContact: {
    create: {
      method: "post",
      path: "/callback/custom_form/wrong_contact",
    },
  },
  callbackMailgun: {
    create: {
      method: "post",
      path: "/callback/mailgun/",
    },
  },
  callbackMailgunCcTracking: {
    create: {
      method: "post",
      path: "/callback/mailgun/cc_tracking",
    },
  },
  callbackRvxEmailTracker: {
    create: {
      method: "post",
      path: "/callback/rvx_email_tracker",
    },
  },
  callbackSendgrid: {
    create: {
      method: "post",
      path: "/callback/sendgrid/",
    },
  },
  callbackSendgridReply: {
    create: {
      method: "post",
      path: "/callback/sendgrid/reply",
    },
  },
  callbackTwilioCall: {
    create: {
      method: "post",
      path: "/callback/twilio/call",
    },
  },
  callbackTwilioCallInbound: {
    create: {
      method: "post",
      path: "/callback/twilio/call/inbound",
    },
  },
  callbackTwilioConfStatus: {
    create: {
      method: "post",
      path: "/callback/twilio/conf_status",
    },
  },
  callbackTwilioLeaveVoicemail: {
    create: {
      method: "post",
      path: "/callback/twilio/leave_voicemail/*",
    },
  },
  callbackTwilioPlayVoicemail: {
    read: {
      method: "get",
      path: "/callback/twilio/play_voicemail",
    },
  },
  callbackTwilioRecording: {
    create: {
      method: "post",
      path: "/callback/twilio/recording/*",
    },
  },
  callbackTwilioStatus: {
    create: {
      method: "post",
      path: "/callback/twilio/status",
    },
  },
  callbackTypeform: {
    create: {
      method: "post",
      path: "/callback/typeform/",
    },
  },
  callbackZendesk: {
    create: {
      method: "post",
      path: "/callback/zendesk/",
    },
  },
  campaign: {
    create: {
      method: "post",
      path: "/campaign",
    },
    edit: {
      method: "put",
      path: "/campaign/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/*",
    },
    read: {
      method: "get",
      path: "/campaign/*",
    },
  },
  campaignActivity: {
    read: {
      method: "get",
      path: "/campaign/activity/*",
    },
    create: {
      method: "post",
      path: "/campaign/activity",
    },
    edit: {
      method: "put",
      path: "/campaign/activity/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/activity/*",
    },
  },
  campaignCallStatuses: {
    read: {
      method: "get",
      path: "/campaign/call_statuses",
    },
  },
  campaignForFilters: {
    read: {
      method: "get",
      path: "/campaign/for_filters",
    },
  },
  campaignStatus: {
    edit: {
      method: "put",
      path: "/campaign/status/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/status/*",
    },
    read: {
      method: "get",
      path: "/campaign/status/*",
    },
  },
  campaignStatuses: {
    create: {
      method: "post",
      path: "/campaign/statuses",
    },
    read: {
      method: "get",
      path: "/campaign/statuses",
    },
    readAsTable: {
      method: "get",
      path: "/campaign/statuses/table",
    },
  },
  investigationStatus: {
    create: {
      method: "post",
      path: "/case/statuses",
    },
    read: {
      method: "get",
      path: "/case/statuses",
    },
    readAsTable: {
      method: "get",
      path: "/case/statuses/table",
    },
    delete: {
      method: "delete",
      path: "/case/statuses/*",
    },
    edit: {
      method: "put",
      path: "/case/statuses/*",
    },
  },
  campaignStatusPreset: {
    read: {
      method: "get",
      path: "/campaign_status_preset",
    },
    readAsTable: {
      method: "get",
      path: "/campaign_status_preset/table",
    },
    create: {
      method: "post",
      path: "/campaign_status_preset",
    },
    edit: {
      method: "put",
      path: "/campaign_status_preset/*",
    },
    delete: {
      method: "delete",
      path: "/campaign_status_preset/*",
    },
  },
  campaignActionEmail: {
    create: {
      method: "post",
      path: "/campaign/*/action/email",
    },
  },
  campaignCall: {
    read: {
      method: "get",
      path: "/campaign/*/call/*",
    },
  },
  campaignCalled: {
    read: {
      method: "get",
      path: "/campaign/*/called",
    },
  },
  campaignContactSearch: {
    read: {
      method: "get",
      path: "/campaign/*/contact/search/*",
    },
  },
  campaignEmail: {
    read: {
      method: "get",
      path: "/campaign/*/email",
    },
  },
  campaignHasEmailableContacts: {
    read: {
      method: "get",
      path: "/campaign/*/has_emailable_contacts",
    },
  },
  campaignNotified: {
    read: {
      method: "get",
      path: "/campaign/*/notified",
    },
  },
  campaignSchedule: {
    create: {
      method: "post",
      path: "/campaign/*/schedule",
    },
    edit: {
      method: "put",
      path: "/campaign/*/schedule/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/*/schedule/*",
    },
    read: {
      method: "get",
      path: "/campaign/*/schedule/*",
    },
  },
  campaignScheduleValidate: {
    create: {
      method: "post",
      path: "/campaign/*/schedule/validate/*",
    },
  },
  campaignScheduleSync: {
    create: {
      method: "post",
      path: "/campaign/*/schedule/*/sync",
    },
  },
  statusInCampaign: {
    edit: {
      method: "put",
      path: "/campaign/*/status/*",
    },
  },
  campaignTarget: {
    create: {
      method: "post",
      path: "/campaign/*/target",
    },
    edit: {
      method: "put",
      path: "/campaign/*/target/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/*/target/*",
    },
    read: {
      method: "get",
      path: "/campaign/*/target/*",
    },
  },
  campaignTargetActivity: {
    read: {
      method: "get",
      path: "/campaign/target/activity/*",
    },
    create: {
      method: "post",
      path: "/campaign/target/activity",
    },
    edit: {
      method: "put",
      path: "/campaign/target/activity/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/target/activity/*",
    },
  },
  campaignTargetAssign: {
    create: {
      method: "post",
      path: "/campaign/*/target/assign/",
    },
  },
  campaignTargetChangeStatus: {
    edit: {
      method: "put",
      path: "/campaign/*/target/*/change_status/*",
    },
  },
  campaignTargetContactsExport: {
    read: {
      method: "get",
      path: "/campaign/*/target/contacts/export",
    },
  },
  campaignTargetEmail: {
    create: {
      method: "post",
      path: "/campaign/*/target/*/email",
    },
    read: {
      method: "get",
      path: "/campaign/*/target/*/email/*",
    },
  },
  emailTags: {
    create: {
      method: "post",
      path: "/email/tags",
    },
    delete: {
      method: "delete",
      path: "/email/tags",
    },
    read: {
      method: "get",
      path: "/email/tags",
    },
  },
  campaignTargetExport: {
    read: {
      method: "get",
      path: "/campaign/*/target/export/",
    },
  },
  campaignTargetImport: {
    create: {
      method: "post",
      path: "/campaign/*/target/import",
    },
    edit: {
      method: "put",
      path: "/campaign/*/target/import",
    },
  },
  campaignTargetCall: {
    readTable: {
      method: "get",
      path: "/call/",
    },
    edit: {
      method: "put",
      path: "/call/*",
    },
    read: {
      method: "get",
      path: "/call/*",
    },
    create: {
      method: "post",
      path: "/call/*",
    },
  },
  callTags: {
    create: {
      method: "post",
      path: "/call/tags",
    },
    delete: {
      method: "delete",
      path: "/call/tags",
    },
    read: {
      method: "get",
      path: "/call/tags",
    },
  },
  campaignTargetContact: {
    read: {
      method: "get",
      path: "/campaign_target_contact/*",
    },
  },
  campaignTargetContacts: {
    create: {
      method: "post",
      path: "/campaign/*/target/*/contact/*",
    },
    delete: {
      method: "delete",
      path: "/campaign/*/target/*/contact/*",
    },
  },
  campaigns: {
    read: {
      method: "get",
      path: "/campaign/",
    },
  },
  caseDomains: {
    read: {
      method: "get",
      path: "/case_domain/",
    },
    create: {
      method: "post",
      path: "/case_domain",
    },
    edit: {
      method: "put",
      path: "/case_domain/*",
    },
    delete: {
      method: "delete",
      path: "/case_domain/*",
    },
  },
  caseEvents: {
    read: {
      method: "get",
      path: "/case_event/",
    },
  },
  case: {
    read: {
      method: "get",
      path: "/case/",
    },
    create: {
      method: "post",
      path: "/case",
    },
    edit: {
      method: "put",
      path: "/case/*",
    },
    delete: {
      method: "delete",
      path: "/case/*",
    },
  },
  caseTags: {
    create: {
      method: "post",
      path: "/case/tags",
    },
    delete: {
      method: "delete",
      path: "/case/tags",
    },
    read: {
      method: "get",
      path: "/case/tags",
    },
  },
  constantContactAccounts: {
    create: {
      method: "post",
      path: "/constant_contact/account",
    },
    edit: {
      method: "put",
      path: "/constant_contact/account/*",
    },
    delete: {
      method: "delete",
      path: "/constant_contact/account/*",
    },
    read: {
      method: "get",
      path: "/constant_contact/account/*",
    },
    auth: {
      method: "get",
      path: "/constant_contact/account/*/auth",
    },
  },
  constantContactCampaigns: {
    create: {
      method: "post",
      path: "/constant_contact/campaigns",
    },
    edit: {
      method: "put",
      path: "/constant_contact/campaigns/*",
    },
    delete: {
      method: "delete",
      path: "/constant_contact/campaigns/*",
    },
    read: {
      method: "get",
      path: "/constant_contact/campaigns/*",
    },
  },
  contact: {
    read: {
      method: "get",
      path: "/contact/",
    },
  },
  contactActivity: {
    read: {
      method: "get",
      path: "/contact/activity/*",
    },
    create: {
      method: "post",
      path: "/contact/activity",
    },
    edit: {
      method: "put",
      path: "/contact/activity/*",
    },
    delete: {
      method: "delete",
      path: "/contact/activity/*",
    },
  },
  contactCustomFields: {
    read: {
      method: "get",
      path: "/contact/custom_fields",
    },
  },
  contactReferral: {
    create: {
      method: "post",
      path: "/contact/referral",
    },
  },
  contactMerge: {
    create: {
      method: "post",
      path: "/contact/merge",
    },
  },
  contactCampaignTable: {
    read: {
      method: "get",
      path: "/contact/*/campaign_table",
    },
  },
  contactCampaigns: {
    read: {
      method: "get",
      path: "/contact/*/campaigns",
    },
  },
  contactEmail: {
    create: {
      method: "post",
      path: "/contact/*/email",
    },
  },
  contactEmailResend: {
    create: {
      method: "post",
      path: "/contact/*/email/*/resend",
    },
  },
  contactEmails: {
    read: {
      method: "get",
      path: "/contact/*/emails/",
    },
  },
  contactTags: {
    create: {
      method: "post",
      path: "/contact/tags",
    },
    delete: {
      method: "delete",
      path: "/contact/tags",
    },
    read: {
      method: "get",
      path: "/contact/tags",
    },
  },
  customField: {
    readTable: {
      method: "get",
      path: "/custom_field/table",
    },
    edit: {
      method: "put",
      path: "/custom_field/",
    },
    delete: {
      method: "delete",
      path: "/custom_field/",
    },
  },
  callDashboard: {
    read: {
      method: "get",
      path: "/dashboard/",
    },
  },
  customFieldConfig: {
    readTable: {
      method: "get",
      path: "/custom_field/configs/",
    },
    create: {
      method: "post",
      path: "/custom_field/configs/",
    },
    edit: {
      method: "put",
      path: "/custom_field/configs/*",
    },
    delete: {
      method: "delete",
      path: "/custom_field/configs/*",
    },
  },
  customFieldGroup: {
    readTable: {
      method: "get",
      path: "/custom_field_group/",
    },
    create: {
      method: "post",
      path: "/custom_field_group/",
    },
    read: {
      method: "get",
      path: "/custom_field_group/*",
    },
    edit: {
      method: "put",
      path: "/custom_field_group/*",
    },
    delete: {
      method: "delete",
      path: "/custom_field_group/*",
    },
  },
  customFieldGrouping: {
    create: {
      method: "post",
      path: "/custom_field_group/assign/",
    },
    delete: {
      method: "delete",
      path: "/custom_field_group/assign/",
    },
  },
  dialSession: {
    create: {
      method: "post",
      path: "/dial_session",
    },
    edit: {
      method: "put",
      path: "/dial_session/*",
    },
    delete: {
      method: "delete",
      path: "/dial_session/*",
    },
    read: {
      method: "get",
      path: "/dial_session",
    },
  },
  dialSessionPrivate: {
    create: {
      method: "post",
      path: "/dial_session/private/",
    },
  },
  dialSessionCallerId: {
    read: {
      method: "get",
      path: "/dial_session/caller_id/*",
    },
  },
  dialSessionResync: {
    read: {
      method: "get",
      path: "/dial_session/*/resync",
    },
  },
  disposition: {
    create: {
      method: "post",
      path: "/disposition",
    },
    edit: {
      method: "put",
      path: "/disposition/*",
    },
    delete: {
      method: "delete",
      path: "/disposition/*",
    },
    read: {
      method: "get",
      path: "/disposition",
    },
  },
  emailTemplates: {
    create: {
      method: "post",
      path: "/email/assets/templates",
    },
    edit: {
      method: "put",
      path: "/email/assets/templates/*",
    },
    delete: {
      method: "delete",
      path: "/email/assets/templates/*",
    },
    read: {
      method: "get",
      path: "/email/assets/templates/*",
    },
  },
  emailTemplatesTags: {
    create: {
      method: "post",
      path: "/email/assets/templates/tags",
    },
    read: {
      method: "get",
      path: "/email/assets/templates/tags",
    },
    delete: {
      method: "delete",
      path: "/email/assets/templates/tags",
    },
  },
  emailTemplatesRender: {
    read: {
      method: "get",
      path: "/email/assets/templates/*/render",
    },
  },
  emailFooters: {
    create: {
      method: "post",
      path: "/email/assets/footers",
    },
    edit: {
      method: "put",
      path: "/email/assets/footers/*",
    },
    delete: {
      method: "delete",
      path: "/email/assets/footers/*",
    },
    read: {
      method: "get",
      path: "/email/assets/footers/*",
    },
  },
  emailFootersTags: {
    create: {
      method: "post",
      path: "/email/assets/footers/tags",
    },
    delete: {
      method: "delete",
      path: "/email/assets/footers/tags",
    },
    read: {
      method: "get",
      path: "/email/assets/footers/tag",
    },
  },
  emailSignatures: {
    create: {
      method: "post",
      path: "/email/assets/signatures",
    },
    edit: {
      method: "put",
      path: "/email/assets/signatures/*",
    },
    delete: {
      method: "delete",
      path: "/email/assets/signatures/*",
    },
    read: {
      method: "get",
      path: "/email/assets/signatures/*",
    },
  },
  emailSignaturesTags: {
    create: {
      method: "post",
      path: "/email/assets/signatures/tags",
    },
    delete: {
      method: "delete",
      path: "/email/assets/signatures/tags",
    },
    read: {
      method: "get",
      path: "/email/assets/signatures/tags",
    },
  },
  emailConfigSmtp: {
    create: {
      method: "post",
      path: "/email_config/smtp",
    },
    edit: {
      method: "put",
      path: "/email_config/smtp/*",
    },
    delete: {
      method: "delete",
      path: "/email_config/smtp/*",
    },
    read: {
      method: "get",
      path: "/email_config/smtp/*",
    },
  },
  emailConfigSmtpDefault: {
    read: {
      method: "get",
      path: "/email_config/smtp/default",
    },
  },
  emailConfigSmtpTable: {
    read: {
      method: "get",
      path: "/email_config/smtp/table",
    },
  },
  emailConfigSmtpTest: {
    create: {
      method: "post",
      path: "/email_config/smtp/test",
    },
  },
  emailUrl: {
    create: {
      method: "post",
      path: "/email_url",
    },
    edit: {
      method: "put",
      path: "/email_url/*",
    },
    delete: {
      method: "delete",
      path: "/email_url/*",
    },
    read: {
      method: "get",
      path: "/email_url/",
    },
    readTable: {
      method: "get",
      path: "/email_url/table",
    },
    generateUrl: {
      method: "get",
      path: "/email_url/*/generate_url",
    },
    editQuestions: {
      method: "put",
      path: "/email_url/*/questions",
    },
    readQuestions: {
      method: "get",
      path: "/email_url/*/questions",
    },
  },
  emailUrlTags: {
    create: {
      method: "post",
      path: "/email_url/tags",
    },
    delete: {
      method: "delete",
      path: "/email_url/tags",
    },
    read: {
      method: "get",
      path: "/email_url/tags",
    },
  },
  submittedFormResponse: {
    read: {
      method: "get",
      path: "/email_url/submitted_form",
    },
  },
  emailUrlForms: {
    read: {
      method: "get",
      path: "/email_url/forms",
    },
  },
  entity: {
    create: {
      method: "post",
      path: "/entity",
    },
    edit: {
      method: "put",
      path: "/entity/*",
    },
    delete: {
      method: "delete",
      path: "/entity/*",
    },
    read: {
      method: "get",
      path: "/entity/*",
    },
  },
  entityActivity: {
    read: {
      method: "get",
      path: "/entity/activity/*",
    },
    create: {
      method: "post",
      path: "/entity/activity",
    },
    edit: {
      method: "put",
      path: "/entity/activity/*",
    },
    delete: {
      method: "delete",
      path: "/entity/activity/*",
    },
  },
  entityCustomFields: {
    read: {
      method: "get",
      path: "/entity/custom_fields",
    },
  },
  entityDepartment: {
    read: {
      method: "get",
      path: "/entity/*/departments",
    },
    create: {
      method: "post",
      path: "/entity/*/departments",
    },
    edit: {
      method: "put",
      path: "/entity/*/department/*",
    },
    delete: {
      method: "delete",
      path: "/entity/*/department/*",
    },
  },
  entityForFilters: {
    read: {
      method: "get",
      path: "/entity/for_filters",
    },
  },
  entityMerge: {
    create: {
      method: "post",
      path: "/entity/merge",
    },
  },
  entityAccountManager: {
    create: {
      method: "post",
      path: "/entity/*/account_manager/*",
    },
    delete: {
      method: "delete",
      path: "/entity/*/account_manager/*",
    },
  },
  entityCampaignTable: {
    read: {
      method: "get",
      path: "/entity/*/campaign_table",
    },
  },
  entityCampaigns: {
    read: {
      method: "get",
      path: "/entity/*/campaigns",
    },
  },
  entityContact: {
    create: {
      method: "post",
      path: "/entity/*/contact",
    },
    edit: {
      method: "put",
      path: "/entity/*/contact/*",
    },
    delete: {
      method: "delete",
      path: "/entity/*/contact/*",
    },
  },
  entityContactSearch: {
    read: {
      method: "get",
      path: "/entity/*/contact/search/*",
    },
  },
  entityEmail: {
    create: {
      method: "post",
      path: "/entity/*/email",
    },
  },
  entityEmails: {
    read: {
      method: "get",
      path: "/entity/*/emails/",
    },
  },
  entityTags: {
    create: {
      method: "post",
      path: "/entity/tags",
    },
    delete: {
      method: "delete",
      path: "/entity/tags",
    },
    read: {
      method: "get",
      path: "/entity/tags",
    },
  },
  contract: {
    readTable: {
      method: "get",
      path: "/entity/*/contract/",
    },
    create: {
      method: "post",
      path: "/entity/*/contract/",
    },
    delete: {
      method: "delete",
      path: "/entity/*/contract/*",
    },
    edit: {
      method: "put",
      path: "/entity/*/contract/*",
    },
    read: {
      method: "get",
      path: "/entity/*/contract/*",
    },
  },
  contractCustomField: {
    read: {
      method: "get",
      path: "/entity/*/contract/custom_field",
    },
  },
  contractForFilters: {
    read: {
      method: "get",
      path: "/entity/*/contract/for_filters",
    },
  },
  revenueOpportunity: {
    readTable: {
      method: "get",
      path: "/revenue_opportunity/",
    },
    create: {
      method: "post",
      path: "/revenue_opportunity/",
    },
    delete: {
      method: "delete",
      path: "/revenue_opportunity/*",
    },
    edit: {
      method: "put",
      path: "/revenue_opportunity/*",
    },
  },
  revenueOpportunityStatusPreset: {
    read: {
      method: "get",
      path: "/revenue_opportunity_status_preset/",
    },
    readAsTable: {
      method: "get",
      path: "/revenue_opportunity_status_preset/table",
    },
    create: {
      method: "post",
      path: "/revenue_opportunity_status_preset",
    },
    edit: {
      method: "put",
      path: "/revenue_opportunity_status_preset/*",
    },
    delete: {
      method: "delete",
      path: "/revenue_opportunity_status_preset/*",
    },
  },
  revenueOpportunityCampaignTarget: {
    read: {
      method: "get",
      path: "/revenue_opportunity/campaign/*",
    },
    edit: {
      method: "post",
      path: "/revenue_opportunity/campaign/*",
    },
  },
  revenueOpportunityStatus: {
    read: {
      method: "get",
      path: "/revenue_opportunity/statuses",
    },
    create: {
      method: "post",
      path: "/revenue_opportunity/statuses",
    },
    edit: {
      method: "put",
      path: "/revenue_opportunity/statuses/*",
    },
    delete: {
      method: "delete",
      path: "/revenue_opportunity/statuses/*",
    },
  },
  formResponse: {
    read: {
      method: "get",
      path: "/form_response/",
    },
  },
  formResponseAttachments: {
    read: {
      method: "get",
      path: "/form_response/attachments",
    },
  },
  machine: {
    read: {
      method: "get",
      path: "/machine/",
    },
    create: {
      method: "post",
      path: "/machine",
    },
    edit: {
      method: "put",
      path: "/machine/*",
    },
    delete: {
      method: "delete",
      path: "/machine/*",
    },
    approve: {
      method: "put",
      path: "/machine/set_approved",
    },
    reject: {
      method: "put",
      path: "/machine/set_rejected",
    },
    review: {
      method: "put",
      path: "/machine/set_review",
    },
  },
  machineTags: {
    create: {
      method: "post",
      path: "/machine/tags",
    },
    delete: {
      method: "delete",
      path: "/machine/tags",
    },
    read: {
      method: "get",
      path: "/machine/tags",
    },
  },
  notifications: {
    read: {
      method: "get",
      path: "/notification/",
    },
    markAsRead: {
      method: "post",
      path: "/notification/mark_as_read/",
    },
    archive: {
      method: "post",
      path: "/notification/mark_as_archived/",
    },
  },
  region: {
    create: {
      method: "post",
      path: "/region",
    },
    edit: {
      method: "put",
      path: "/region/*",
    },
    delete: {
      method: "delete",
      path: "/region/*",
    },
    read: {
      method: "get",
      path: "/region/",
    },
  },
  sequence: {
    create: {
      method: "post",
      path: "/sequence/",
    },
    edit: {
      method: "put",
      path: "/sequence/*",
    },
    delete: {
      method: "delete",
      path: "/sequence/*",
    },
    read: {
      method: "get",
      path: "/sequence/",
    },
  },
  sequenceCampaigns: {
    create: {
      method: "post",
      path: "/sequence/*/campaigns",
    },
  },
  sourceStatus: {
    read: {
      method: "get",
      path: "/source_status/",
    },
    create: {
      method: "post",
      path: "/source_status",
    },
    edit: {
      method: "put",
      path: "/source_status/*",
    },
    delete: {
      method: "delete",
      path: "/source_status/*",
    },
  },
  sourceStatusForFilters: {
    read: {
      method: "get",
      path: "/source_status/for_filters",
    },
  },
  tag: {
    read: {
      method: "get",
      path: "/tag",
    },
    readTable: {
      method: "get",
      path: "/tags/table/",
    },
    create: {
      method: "post",
      path: "/tag",
    },
    edit: {
      method: "put",
      path: "/tag/*",
    },
    delete: {
      method: "delete",
      path: "/tag/*",
    },
  },
  tagBulkUpdateCategory: {
    edit: {
      method: "put",
      path: "/tag/update_category",
    },
  },
  tagCategory: {
    read: {
      method: "get",
      path: "/tag_category",
    },
    readTable: {
      method: "get",
      path: "/tag_category/table/",
    },
    create: {
      method: "post",
      path: "/tag_category",
    },
    edit: {
      method: "put",
      path: "/tag_category/*",
    },
    delete: {
      method: "delete",
      path: "/tag_category/*",
    },
  },
  tagCategoryForFilters: {
    read: {
      method: "get",
      path: "/tag_category/for_filters",
    },
  },
  task: {
    read: {
      method: "get",
      path: "/task",
    },
    create: {
      method: "post",
      path: "/task",
    },
    edit: {
      method: "put",
      path: "/task/*",
    },
    markAsCompleted: {
      method: "post",
      path: "/task/*/mark_as_completed",
    },
    attachFile: {
      method: "post",
      path: "/task/*/attach_file",
    },
  },
  taskActivity: {
    read: {
      method: "get",
      path: "/task/activity/*",
    },
    create: {
      method: "post",
      path: "/task/activity",
    },
    edit: {
      method: "put",
      path: "/task/activity/*",
    },
    delete: {
      method: "delete",
      path: "/task/activity/*",
    },
  },
  taskTags: {
    create: {
      method: "post",
      path: "/task/tags",
    },
    read: {
      method: "get",
      path: "/task/tags",
    },
    delete: {
      method: "delete",
      path: "/task/tags",
    },
  },
  twilioAddToCall: {
    create: {
      method: "post",
      path: "/twilio/add_to_call/*",
    },
  },
  twilioDisposition: {
    create: {
      method: "post",
      path: "/twilio/disposition",
    },
  },
  twilioRemoveFromCall: {
    create: {
      method: "post",
      path: "/twilio/remove_from_call/*",
    },
  },
  twilioToken: {
    read: {
      method: "get",
      path: "/twilio/token",
    },
  },
  twilioUpdateParticipant: {
    create: {
      method: "post",
      path: "/twilio/update_participant/*",
    },
  },
  unsubscribe: {
    create: {
      method: "post",
      path: "/unsubscribe/",
    },
  },
  user: {
    create: {
      method: "post",
      path: "/user/",
    },
    read: {
      method: "get",
      path: "/user/",
    },
  },
  callAgentStatus: {
    edit: {
      method: "put",
      path: "/agent_status/*",
    },
    read: {
      method: "get",
      path: "/agent_status/*",
    },
  },
  userSearch: {
    read: {
      method: "get",
      path: "/user/search/*",
    },
  },
  userSettings: {
    edit: {
      method: "put",
      path: "/user/settings",
    },
    read: {
      method: "get",
      path: "/user/settings",
    },
  },
  userAlerts: {
    read: {
      method: "get",
      path: "/user/alerts",
    },
  },
  userSettingsNotifications: {
    edit: {
      method: "put",
      path: "/user/settings/notifications/*/*",
    },
    read: {
      method: "get",
      path: "/user/settings/notifications/*/*",
    },
  },
  dataJob: {
    create: {
      method: "post",
      path: "/data_job",
    },
    delete: {
      method: "delete",
      path: "/data_job/*",
    },
    read: {
      method: "get",
      path: "/data_job/*",
    },
  },
  dataJobTags: {
    create: {
      method: "post",
      path: "/data_job/tags",
    },
    delete: {
      method: "delete",
      path: "/data_job/tags",
    },
    read: {
      method: "get",
      path: "/data_job/tags",
    },
  },
  importData: {
    create: {
      method: "post",
      path: "/data_job/import/data",
    },
    edit: {
      method: "put",
      path: "/data_job/import/data",
    },
  },
  importLogs: {
    create: {
      method: "post",
      path: "/data_job/import/logs",
    },
  },
  exportContacts: {
    create: {
      method: "post",
      path: "/data_job/export/contact",
    },
  },
  exportTargets: {
    create: {
      method: "post",
      path: "/data_job/export/campaign_target",
    },
  },
  exportLogs: {
    create: {
      method: "post",
      path: "/data_job/export/audit_log",
    },
  },
  dataTemplate: {
    read: {
      method: "get",
      path: "/data_job/import/template/*",
    },
  },
  logsTemplate: {
    read: {
      method: "get",
      path: "/data_job/import/template/*",
    },
  },
  voicemailForFilters: {
    read: {
      method: "get",
      path: "/voicemail/for_filters",
    },
  },
  voicemail: {
    create: {
      method: "post",
      path: "/voicemail",
    },
    edit: {
      method: "put",
      path: "/voicemail/*",
    },
    delete: {
      method: "delete",
      path: "/voicemail/*",
    },
    read: {
      method: "get",
      path: "/voicemail/",
    },
  },
  lead: {
    read: {
      method: "get",
      path: "/lead/",
    },
    create: {
      method: "post",
      path: "/lead",
    },
    edit: {
      method: "put",
      path: "/lead/*",
    },
    delete: {
      method: "delete",
      path: "/lead/*",
    },
    approve: {
      method: "put",
      path: "/lead/approve",
    },
    reject: {
      method: "put",
      path: "/lead/reject",
    },
    addToCase: {
      method: "put",
      path: "/lead/add_to_case",
    },
  },
  leadTags: {
    create: {
      method: "post",
      path: "/lead/tags",
    },
    delete: {
      method: "delete",
      path: "/lead/tags",
    },
    read: {
      method: "get",
      path: "/lead/tags",
    },
  },
  product: {
    create: {
      method: "post",
      path: "/product/",
    },
    edit: {
      method: "put",
      path: "/product/*",
    },
    delete: {
      method: "delete",
      path: "/product/*",
    },
    read: {
      method: "get",
      path: "/product/",
    },
  },
  recipient: {
    create: {
      method: "post",
      path: "/recipient/",
    },
    edit: {
      method: "put",
      path: "/recipient/*",
    },
    delete: {
      method: "delete",
      path: "/recipient/*",
    },
    read: {
      method: "get",
      path: "/recipient/",
    },
  },
  twilioPhoneNumber: {
    readAsTable: {
      method: "get",
      path: "/twilio_phone_number/",
    },
    assignAgents: {
      method: "put",
      path: "/twilio_phone_number/assign",
    },
  },
  MachineApprovalStatus: {
    create: {
      method: "post",
      path: "/machine_approval_status/",
    },
    edit: {
      method: "put",
      path: "/machine_approval_status/*",
    },
    delete: {
      method: "delete",
      path: "/machine_approval_status/*",
    },
    read: {
      method: "get",
      path: "/machine_approval_status/",
    },
  },
  textMessage: {
    create: {
      method: "post",
      path: "/text_message/",
    },
  },
  customEmailDomains: {
    readTable: {
      method: "get",
      path: "/mailgun_domain/",
    },
    read: {
      method: "get",
      path: "/mailgun_domain/*",
    },
    create: {
      method: "post",
      path: "/mailgun_domain/",
    },
    delete: {
      method: "delete",
      path: "/mailgun_domain/*",
    },
    update: {
      method: "put",
      path: "/mailgun_domain/*",
    },
  },
};

export default ACL_RELATIONSHIPS;
