import React from "react";
import { Checkbox, Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";

import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import { checkIsAuthorized } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import FormModal from "../../../components/modals/FormModal";
import TagList from "../../../components/TagList";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import EmailUrlService from "../../../services/EmailUrls";

import "../../../styles/table.scss";
import _DeleteEmailUrlModal from "./DeleteEmailUrlModal";
import _EmailUrlModal from "./EmailUrlModal";
import QuestionsForm from "./QuestionsForm";

const QuestionsModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.emailUrl.editQuestions,
  ACL_RELATIONSHIPS.emailUrl.readQuestions,
]);

const EmailUrlModal = withRoleCheck(_EmailUrlModal, [
  ACL_RELATIONSHIPS.emailUrl.create,
]);
const EditEmailUrlModal = withRoleCheck(_EmailUrlModal, [
  ACL_RELATIONSHIPS.emailUrl.edit,
]);
const DeleteEmailUrlModal = withRoleCheck(_DeleteEmailUrlModal, [
  ACL_RELATIONSHIPS.emailUrl.delete,
]);

class EmailUrlsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = EmailUrlService.getEmailUrlsTable;

    this.state = {
      ...this.state,
      header: "Email URLs",
      headerIcon: "linkify",
      className: "EmailUrl",
      tableName: "email_urls",
      noDataText: "No Email URLs found. Create one.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.emailUrlTags.create,
        ACL_RELATIONSHIPS.emailUrlTags.read,
        ACL_RELATIONSHIPS.emailUrlTags.delete,
      ]),
      createButton: <EmailUrlModal button onSuccess={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("Email URLs");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
      await this.fetchTags();
      this.setState({
        createButton: <EmailUrlModal button onSuccess={this.fetchData} />,
      });
    }
  }

  deleteEmailUrl = async id => {
    await EmailUrlService.deleteEmailUrl(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        width: 40,
        headerClassName: "centered",
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        width: 40,
        accessor: "id",
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "URL",
        accessor: "url",
      },
      {
        id: "is_form",
        Header: "Is Form",
        accessor: d => d.is_form.toString(),
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Entity Tags",
        accessor: "entity_auto_tags",
        sortable: false,
        headerClassName: "non-sortable",
      },
      {
        Header: "Contact Tags",
        accessor: "contact_auto_tags",
        sortable: false,
        headerClassName: "non-sortable",
      },
      {
        Header: "Notify Email",
        accessor: "notify_email",
        sortable: false,
        headerClassName: "non-sortable",
      },
      {
        Header: "Clicky ID",
        accessor: "clicky_id",
      },
      {
        Header: "Clicky Key",
        accessor: "clicky_key",
      },
      {
        id: "active",
        Header: "Active",
        accessor: d => d.active.toString(),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditEmailUrlModal
                emailUrlId={props.original.id}
                onSuccess={this.fetchData}
              />
              {props.original.is_form && (
                <QuestionsModal
                  onSuccess={this.fetchData}
                  modelType="Questions"
                  model={props.original}
                  dropdown
                  icon="question circle"
                  FormComponent={QuestionsForm}
                />
              )}
              <DeleteEmailUrlModal
                menuTrigger
                onConfirmDelete={() => this.deleteEmailUrl(props.original.id)}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    const { tags, customFields } = this.state;

    let filters = [
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    customFields.forEach(field => {
      filters.push({
        key: `search:custom:${field}`,
        title: field,
        type: "input",
      });
    });
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default EmailUrlsTable;
