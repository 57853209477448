import React from "react";
import { List, Button, Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DepartmentService from "../../services/Department";
import ContactService from "./../../services/Contact";
import CampaignScheduleService from "../../services/CampaignScheduleService";
import BaseTable from "../../components/BaseTable";

import "../../styles/campaign.scss";
import "../../styles/table.scss";
import TagList from "../../components/TagList";
import _CampaignStatusModal, {
  getCampaignStatusName,
} from "./components/CampaignStatusModal";
import AuthService from "../../services/Auth";
import ListTableColumn from "../../components/ListTableColumn";
import tablesSettings from "constants/Table";

class ScheduleViewContactsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CampaignScheduleService.getEmailableContactsForSchedule;
    this.queryArgs = [this.props.campaignId, this.props.data];

    this.downloadParams = {
      campaign_id: this.props.campaignId,
      data: JSON.stringify({ ...this.props.data }),
    };

    this.state = {
      ...this.state,
      header: "Email Schedule Contacts",
      headerIcon: "mail outline",
      className: "EmailScheduleContacts",
      tableName: "email_schedule_contacts",
      noDataText: "No contacts found with filters",
      enableCustomFieldsSettings: true,
      createButton: null,
      overrideTags: true,
      exportTableName: "ScheduleViewContactsTable",
    };
  }

  updateFilterOptions = async () => {
    const { users, tags, customFields } = this.state;

    let filters = [
      {
        key: "search:contact_name",
        title: "Contact Name",
        type: "input",
      },
      {
        key: "search:contact_email",
        title: "Contact Email",
        type: "input",
      },
      {
        key: "search:contact_alternate_email",
        title: "Contact Alternate Email",
        type: "input",
      },
      {
        key: "search:entity_name",
        title: "Entity Name",
        type: "input",
      },
      {
        key: "department_id",
        title: "Department Name",
        type: "ruvixxSelect",
        queryFn: DepartmentService.getForFilters,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${field}`,
          title: field,
          type: "input",
        });
      })
    );
    this.setState({ filters });
  };

  fetchCustomFields = async () => {
    const customFields = await ContactService.getCustomFields();
    this.setState(
      {
        customFields: [
          { accessorPrefix: "custom_fields", fields: customFields },
        ],
      },
      this.updateFilterOptions
    );
  };

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchCustomFields();
      this.fetchTags();
    }
  }

  tableLeft = () => {
    if (!("email_count" in this.state.extra)) {
      return null;
    }

    const button = (
      <Button className="standard no-click" size="tiny" icon>
        <span style={{ marginRight: 3 }}>{this.state.extra.email_count}</span>
        <Icon name="mail" />
      </Button>
    );
    return (
      <Popup trigger={button} content="Number of e-mails to be sent"></Popup>
    );
  };

  setColumns = () => {
    const columns = [
      {
        Header: "Entity",
        accessor: "entity_name",
        Cell: props => (
          <Link to={"/entities/" + props.original.entity_id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => (
          <Link to={"/contacts/" + props.original.contact_id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Alternate Names",
        accessor: "alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"name"}
            data={value || []}
          />
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Department",
        accessor: "department_name",
        className: "centered",
      },
      {
        Header: "Alternate Emails",
        accessor: "email_addresses",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {props.value
              .filter(email => !email.is_primary)
              .map(email => (
                <List.Item key={email.id}>{email.email}</List.Item>
              ))}
          </List>
        ),
      },
      {
        Header: "Contact Tags",
        accessor: "contact_tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity Tags",
        accessor: "entity_tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Campaign Status",
        accessor: "campaign_status",
        minWidth: tablesSettings.statusColumnMinWidth,
        Cell: ({ value }) => getCampaignStatusName(value),
      },
      {
        Header: "Last Call Disposition",
        accessor: "last_call_disposition",
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return this.renderTable();
  }
}

export default ScheduleViewContactsTable;
