import React, { Component } from "react";
import {
  Form,
  Segment,
  Select,
  Button,
  Icon,
  Accordion,
  Checkbox,
  Modal,
  Message,
} from "semantic-ui-react";
import store from "store";
import ReactHtmlParser from "react-html-parser";
import RuvixxForm from "../../../components/RuvixxForm";
import EmailTemplateService from "../../../services/EmailTemplate";
import CampaignService from "../../../services/Campaign";
import EmailFooterService from "../../../services/EmailFooters";
import EmailSignatureService from "../../../services/EmailSignatures";
import EntityService from "../../../services/Entity";
import ContactService from "../../../services/Contact";
import SmtpAccountService from "../../../services/SmtpAccounts";
import EmailProviderService from "../../../services/EmailProviders";
import CustomEmailDomainsService from "../../../services/CustomEmailDomains";
import PropTypes from "prop-types";
import UploadManager from "../../../components/UploadManager";
import UploadProgressRing from "../../../components/UploadProgressRing";
import CONSTANTS from "../../../constants/Constants";
import Validate from "../../../components/Validate";

const { EMAIL_SERVICES } = CONSTANTS;

const DEFAULT_PROVIDER = 5;

class NewEmailForm extends Component {
  static propTypes = {
    campaignId: PropTypes.number,
    entities: PropTypes.arrayOf(PropTypes.number),
    contactId: PropTypes.number,
    contactEmail: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    emailService: PropTypes.number,
    onlySmtp: PropTypes.bool,
    followUp: PropTypes.bool,
    contactRows: PropTypes.arrayOf(PropTypes.object),
    checkedArr: PropTypes.arrayOf(PropTypes.number),
  };
  static defaultProps = {
    campaignId: null,
    onlySmtp: false,
    followUp: false,
    contactRows: [],
    checkedArr: [],
  };
  static nullOption = {
    key: null,
    value: null,
    text: "None",
  };
  static nullTemplate = {
    id: null,
    name: "",
    subject: "",
    text: "",
    html: "",
    info: {},
  };

  constructor(props) {
    super(props);
    this.uploadManager = new UploadManager();
    this.uploadManager.addProgressListener(this.handleFileProgress);
    // TODO: Is this attempting to set the from as the user logged in?
    //       If so, I don't believe that is the behavior we should be expecting
    //       The default should be set for the server instance
    this.state = {
      recipients: [],
      value: [],
      contactIds: [],
      cc: "",
      bcc: "",
      ccAccountManagers: false,
      ccExternalAccountManagers: false,
      ccMenuOpen: false,
      fromUser: "",
      fromDomain: "",
      templateOptions: [],
      providerOptions: [],
      smtpOptions: [],
      smtpAccount: {},
      smtp_config_id: null,
      templates: [],
      template: { ...NewEmailForm.nullTemplate },
      campaignTemplates: [],
      files: [],
      provider: "",
      senderName: store.get("userAuth").full_name,
      footerOptions: [],
      footerId: null,
      footerHtml: "",
      signatureOptions: [],
      signatureId: null,
      signatureHtml: "",
      previewHtml: "",
      providerIsSMTP: false,
      providerIsOutlook: false,
      outlookEmailAliases: [],
      outlookAliasSelected: null,
      campaignOptions: [],
      campaign: null,
      includeAlternativeEmails: false,
      mailgunDomainOptions: [],
      mailgunDomainSelected: null,
      errors: {},
    };
  }

  fetchOutlookAliases = async () => {
    const outlookEmailAliases =
      await EmailProviderService.getEmailAliasesForOutlook();
    this.setState({ outlookEmailAliases });
  };

  async componentDidMount() {
    this.fetchRecipients();
    this.fetchTemplates();
    this.fetchCampaign();
    this.fetchCampaigns();
    this.fetchEmailFooters();
    this.fetchEmailSignatures();
    this.fetchOutlookAliases();
    this.fetchMailgunDomains();
    const { onlySmtp } = this.props;
    const smtpAccounts = await SmtpAccountService.getSmtpAccounts();
    const providerOptions = await EmailProviderService.getEmailProviders(
      smtpAccounts.length > 0,
      false,
      onlySmtp
    );
    let defaultProvider = [{ key: 0, text: "Campaign Default", value: 5 }];
    const smtpOptions = smtpAccounts.map(({ id, return_addr }) => ({
      key: id,
      text: return_addr,
      value: id,
    }));
    let smtpAccount = smtpAccounts.find(({ is_default }) => is_default);
    if (smtpAccounts.length > 0 && !smtpAccount) {
      smtpAccount = smtpAccounts[0];
    }

    if (smtpAccount && onlySmtp) {
      this.setSmtpAsProvider(smtpAccount.id);
    }
    this.setState({
      fromDomain: "email.domain.com", // TODO: fetch from backend
      providerOptions: !onlySmtp
        ? defaultProvider.concat(providerOptions)
        : providerOptions,
      smtpOptions,
      smtpAccount,
    });
  }

  templateDisabled = template => {
    let provider = this.state.provider;
    const selectedTemplate = this.state.template;
    const { provider_list: providerList } = template.info;
    if (!provider) return false;
    if (!providerList) return false;
    if (provider === DEFAULT_PROVIDER) {
      provider = this.props.emailService;
    }
    let disabled;
    switch (provider) {
      case CONSTANTS.EMAIL_SERVICES.MAILGUN:
      case CONSTANTS.EMAIL_SERVICES.SENDGRID:
        disabled = !providerList.mailgun_sendgrid;
        break;
      case CONSTANTS.EMAIL_SERVICES.CONSTANT_CONTACT:
        disabled = !providerList.constant_contact;
        break;
      case CONSTANTS.EMAIL_SERVICES.SMTP:
        disabled = !providerList.smtp;
        break;
      default:
        disabled = false;
    }
    if (disabled && selectedTemplate.id === template.id) {
      this.setState(
        {
          template: { ...NewEmailForm.nullTemplate },
        },
        this.combinedTemplate
      );
    }

    return disabled;
  };

  setTemplateOptions = async () => {
    const { templates, campaignTemplates, template } = this.state;
    let templateOptions = templates.map(template => {
      let disabled = this.templateDisabled(template);
      let description = disabled ? "Disabled for selected provider" : null;

      if (!template.enabled) {
        disabled = true;
        description = "Empty template";
      }

      return {
        ...template,
        disabled,
        description,
      };
    });
    if (campaignTemplates && campaignTemplates.length > 0) {
      templateOptions = templateOptions.filter(template => {
        return campaignTemplates.includes(template.id);
      });
    }
    const newTemplate = templateOptions.some(t => t.id === template.id)
      ? template
      : { ...NewEmailForm.nullTemplate };
    this.setState({
      templateOptions,
      template: newTemplate,
    });
  };

  getSelectedContacts = () => {
    let contacts = [];
    if (this.props.checkedArr.length > 0) {
      contacts = this.props.contactRows.filter(row =>
        this.props.checkedArr.includes(row.id)
      );
    }
    return contacts;
  };

  fetchRecipients = async () => {
    let recipients = [];
    let value = [];
    let contacts = [];

    if (this.props.contactId) {
      const emailResponse = await ContactService.getEmailAddresses(
        this.props.contactId
      );
      recipients = emailResponse.filter(
        emailAddress => emailAddress.enabled && emailAddress.subscribed
      );
      value = emailResponse.filter(i => i.is_primary == true).map(i => i.key);
    } else {
      contacts = this.getSelectedContacts();
      if (contacts.length == 0) {
        let filters = {};
        if (this.props.campaignId) {
          filters["campaign_id"] = this.props.campaignId;
        }
        if (this.props.entities.length) {
          filters["entity_id"] = this.props.entities;
        }
        contacts = await ContactService.getEmailableContacts(filters);
      }
      const emailFilter = contact => {
        if (!("email_addresses" in contact)) {
          return [contact];
        }
        if (this.state.includeAlternativeEmails) {
          return contact.email_addresses.filter(email => email.enabled);
        } else
          return contact.email_addresses.filter(
            email => !!email.enabled && email.is_primary
          );
      };
      recipients = contacts.flatMap(contact => {
        return emailFilter(contact).map(email => ({
          key: email.id,
          text: email.email,
          value: email.id,
          contact_id: contact.id,
        }));
      });
      value = recipients.map(i => i.key);
    }
    this.setState({
      recipients,
      value,
      contactIds: contacts.map(contact => contact.id),
    });
  };

  fetchMailgunDomains = async () => {
    const domains = await CustomEmailDomainsService.getVerifiedDomains();
    const domainOptions = domains.map(domain => ({
      key: domain.id,
      value: domain.name,
      text: domain.name,
    }));
    const defaultDomain = domains.find(domain => domain.is_default)?.name;
    this.setState({
      mailgunDomainOptions: domainOptions,
      mailgunDomainSelected: defaultDomain,
    });
  };

  fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    templates = templates.map(template => ({
      id: template.id,
      key: template.id,
      value: template.id,
      text: template.name,
      info: template.info,
      enabled: template.enabled,
    }));
    this.setState({ templates }, this.setTemplateOptions);
  };

  fetchCampaign = async campaignId => {
    if (!campaignId && this.props.campaignId) {
      campaignId = this.props.campaignId;
    }
    if (campaignId) {
      const campaign = await CampaignService.getCampaign(campaignId);
      this.setState(
        {
          campaignTemplates: campaign.info.selected_templates || [],
        },
        this.setTemplateOptions
      );
    }
  };

  fetchCampaigns = async () => {
    const campaigns = await CampaignService.getCampaignsForFilters(
      this.props.contactId
        ? { contact_id: this.props.contactId }
        : { entity_id: this.props.entities[0] }
    );
    const contacts = this.getSelectedContacts();

    const campaignCounts = contacts
      .flatMap(({ campaign_targets }) => campaign_targets)
      .map(({ campaign_id }) => campaign_id)
      .reduce((acc, campaignId) => {
        acc[campaignId] = (acc[campaignId] || 0) + 1;
        return acc;
      }, {});
    const campaignsBelongingToAllContacts = Object.keys(campaignCounts)
      .filter(campaignId => campaignCounts[campaignId] === contacts.length)
      .map(campaignId => +campaignId);

    const campaignOptions = campaigns
      .filter(
        ({ id }) =>
          contacts.length === 0 || campaignsBelongingToAllContacts.includes(id)
      )
      .map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      }));
    this.setState({ campaignOptions });
  };

  fetchEmailFooters = async () => {
    let footers = await EmailFooterService.getEmailFootersForFilters();
    let footerOptions = footers.map(footer => ({
      key: footer.id,
      value: footer.id,
      text: footer.name,
    }));
    footerOptions.unshift({ key: null, value: null, text: "None" });
    this.setState({
      footerOptions: footerOptions,
    });
  };

  fetchEmailSignatures = async () => {
    let signatures = await EmailSignatureService.getEmailSignaturesForFilters();
    let signatureOptions = signatures.map(signature => ({
      key: signature.id,
      value: signature.id,
      text: signature.name,
    }));
    signatureOptions.unshift({ key: null, value: null, text: "None" });
    this.setState({
      signatureOptions: signatureOptions,
    });
  };

  combinedTemplate = () => {
    let template = this.decodeHtml(this.state.template.html);
    let previewHtml;
    let before = template.indexOf("</body>");
    let footer = "";
    let signature = "";
    if (this.state.footerHtml) {
      footer = this.decodeHtml(this.state.footerHtml);
    }
    if (this.state.signatureHtml) {
      signature = this.decodeHtml(this.state.signatureHtml);
    }
    previewHtml =
      template.substring(0, before) +
      signature +
      footer +
      template.substring(before);
    this.setState({ previewHtml: previewHtml });
  };

  handleTemplateChange = async (e, { value }) => {
    const { templates } = this.state;
    let selectedTemplate = templates.find(template => {
      return template.id === value;
    });
    selectedTemplate = selectedTemplate || NewEmailForm.nullTemplate;
    if (selectedTemplate && !selectedTemplate.html) {
      selectedTemplate = await EmailTemplateService.getEmailTemplate(value);
      const idx = templates.indexOf(template => template.id);
      templates[idx] = selectedTemplate;
    }
    this.setState(
      {
        templates: templates,
        template: selectedTemplate,
      },
      this.combinedTemplate
    );
  };

  handleProviderChange = (e, { value }) => {
    const { smtpAccount } = this.state;
    const smtp_config_id =
      value === EMAIL_SERVICES.SMTP && smtpAccount ? smtpAccount.id : null;
    const isSMTP = value === EMAIL_SERVICES.SMTP;
    const isOutlook = value === EMAIL_SERVICES.OUTLOOK;
    const isConstantContact = value === EMAIL_SERVICES.CONSTANT_CONTACT;
    const isConstantContactOrSMTP = isConstantContact || isSMTP;
    this.setState(
      {
        provider: value,
        smtp_config_id,
        providerIsSMTP: isSMTP,
        providerIsOutlook: isOutlook,
        checkFromField: !isConstantContactOrSMTP,
      },
      this.setTemplateOptions
    );
  };

  handleCampaignChange = (_, { value }) => {
    this.fetchCampaign(value);
    this.setState({
      campaign: value,
    });
  };

  setSmtpAsProvider = smtp_config_id => {
    this.setState(
      {
        provider: EMAIL_SERVICES.SMTP,
        smtp_config_id,
        providerIsSMTP: true,
        providerIsOutlook: false,
      },
      this.setTemplateOptions
    );
  };

  handleFooterChange = async (e, { value }) => {
    let footerId = value;
    let footerHtml;
    if (!!footerId) {
      footerHtml = (await EmailFooterService.getEmailFooter(footerId)).html;
    } else {
      footerHtml = null;
    }
    this.setState({ footerHtml, footerId }, this.combinedTemplate);
  };

  handleSignatureChange = async (e, { value }) => {
    let signatureId = value;
    let signatureHtml;
    if (!!signatureId) {
      signatureHtml = (
        await EmailSignatureService.getEmailSignature(signatureId)
      ).html;
    } else {
      signatureHtml = null;
    }
    this.setState({ signatureHtml, signatureId }, this.combinedTemplate);
  };

  handleSubmit = async e => {
    const {
      files,
      senderName,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      value,
      template,
      fromUser,
      provider,
      smtp_config_id,
      smtpAccount,
      footerId,
      signatureId,
      providerIsOutlook,
      outlookAliasSelected,
      campaign,
      includeAlternativeEmails,
      contactIds,
      checkFromField,
      mailgunDomainSelected,
    } = this.state;

    const uploads = files.map(f => f.attachment);

    let errors = {};

    // Validates that the email user part (THIS_PART@domain.com) is valid.
    if (checkFromField && !Validate.emailUsername(fromUser)) {
      this.setState({
        errorMessage: 'Invalid value in "From" field',
      });
    } else {
      // TODO: If some files upload successfully and others fail, it will reupload all
      // of them on the next submit. This will lead to unbound files in the server and
      // could be improved later.
      let fromUserResolved = fromUser;
      if (smtp_config_id) {
        fromUserResolved = smtpAccount.return_addr;
      } else if (providerIsOutlook) {
        fromUserResolved = outlookAliasSelected;
      } else if (provider === CONSTANTS.EMAIL_SERVICES.MAILGUN) {
        fromUserResolved = `${fromUser}@${mailgunDomainSelected}`;
      }

      if (this.props.contactId && this.props.contactId > 0) {
        await ContactService.sendEmail({
          contact_id: this.props.contactId,
          template: template.id,
          attachments: uploads,
          senderName,
          cc,
          bcc,
          ccAccountManagers,
          ccExternalAccountManagers,
          fromUser: fromUserResolved,
          smtp_config_id,
          provider,
          footerId,
          signatureId,
          campaignId: campaign || this.props.campaignId,
          emailAddressIds: value,
          follow_up: this.props.followUp,
        });
      } else if (this.props.forEntity) {
        await EntityService.sendEmail({
          entityId: this.props.entities[0],
          template: template.id,
          attachments: uploads,
          senderName,
          cc,
          bcc,
          ccAccountManagers,
          ccExternalAccountManagers,
          fromUser: fromUserResolved,
          smtp_config_id,
          provider,
          footerId,
          signatureId,
          campaignId: campaign,
          follow_up: this.props.followUp,
        });
      } else {
        await CampaignService.sendEmail({
          campaignId: campaign || this.props.campaignId,
          targetId: this.props.contactId ? this.props.entities[0] : undefined,
          contacts:
            this.props.checkedArr.length > 0
              ? this.props.checkedArr
              : contactIds,
          template: template.id,
          attachments: uploads,
          senderName,
          cc,
          bcc,
          ccAccountManagers,
          ccExternalAccountManagers,
          fromUser: fromUserResolved,
          smtp_config_id,
          provider,
          footerId,
          signatureId,
          follow_up: this.props.followUp,
          ...(includeAlternativeEmails && { emailAddressIds: value }),
        });
      }
      this.setState({ sentModal: true });
    }
  };

  handleSelect = (e, { value }) => {
    this.setState({ value });
  };

  handleChange = (event, data) => {
    this.setState({
      [data.name]: data.type === "checkbox" ? data.checked : data.value,
    });
  };

  handleOutlookAliasesChange = (event, data) =>
    this.setState({ [data.name]: data.value });

  handleFromChange = (event, { value }) => {
    const { smtpOptions } = this.state;
    const smtpAccount = smtpOptions
      .filter(opt => opt.value === value)
      .map(({ value, text }) => ({
        id: value,
        return_addr: text,
      }))[0];
    this.setState({
      smtpAccount,
      smtp_config_id: value,
    });
  };

  handleConfirmationClose = () => {
    this.setState({ sentModal: false });
    this.props.closeParentModal();
  };

  decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  handleFileChange = async event => {
    let fileArr = [];
    for (var key in this.uploadInput.files) {
      if (this.uploadInput.files.hasOwnProperty(key)) {
        fileArr.push(this.uploadInput.files[key]);
      }
    }
    this.setState({ files: fileArr });
    await this.uploadManager.uploadFiles(fileArr);
  };

  handleFileProgress = files => {
    this.setState({
      files: [...files],
    });
  };

  toggleIncludeAlternativeEmails = () => {
    this.setState(
      {
        includeAlternativeEmails: !this.state.includeAlternativeEmails,
      },
      this.fetchRecipients
    );
  };

  render() {
    const {
      fromUser,
      fromDomain,
      recipients,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      ccMenuOpen,
      value,
      templateOptions,
      providerOptions,
      smtpOptions,
      template,
      files,
      provider,
      senderName,
      smtp_config_id,
      previewHtml,
      outlookEmailAliases,
      outlookAliasSelected,
      mailgunDomainOptions,
      mailgunDomainSelected,
      providerIsSMTP,
      providerIsOutlook,
      campaignOptions,
      campaign,
      includeAlternativeEmails,
      errors,
      errorMessage,
    } = this.state;
    const { onlySmtp } = this.props;

    let hasFromUserAddress = !!fromUser;
    if (providerIsOutlook) {
      hasFromUserAddress = outlookAliasSelected !== null;
    } else if (providerIsSMTP) {
      hasFromUserAddress = smtp_config_id !== null;
    }

    let usernameField = (
      <>
        <Form.Input
          name="fromUser"
          label="From"
          placeholder="ex. username"
          value={fromUser}
          onChange={this.handleChange}
          required
          readOnly={providerIsSMTP}
          error={!!errorMessage}
        />
        <div
          style={{ paddingTop: "2rem", marginLeft: "-1rem" }}
          hidden={!fromDomain || providerIsSMTP}
        >
          {`@${fromDomain}`}
        </div>
      </>
    );

    if (providerIsSMTP) {
      usernameField = (
        <Form.Field
          required
          control={Select}
          label="From"
          options={smtpOptions}
          onChange={this.handleFromChange}
          value={smtp_config_id}
        />
      );
    } else if (providerIsOutlook) {
      usernameField = (
        <Form.Select
          name="outlookAliasSelected"
          selection
          label="Select an alias"
          options={outlookEmailAliases}
          onChange={this.handleOutlookAliasesChange}
          value={outlookAliasSelected}
          required
        />
      );
    } else if (provider === CONSTANTS.EMAIL_SERVICES.MAILGUN) {
      usernameField = (
        <>
          <Form.Input
            label="From"
            placeholder="ex. username"
            onChange={this.handleChange}
            name="fromUser"
            value={fromUser}
            error={!!errorMessage}
          />
          @
          <Form.Select
            name="mailgunDomainSelected"
            selection
            label="Select a domain"
            options={mailgunDomainOptions}
            onChange={this.handleChange}
            value={mailgunDomainSelected}
            required
          />
        </>
      );
    }

    return (
      <>
        <Modal
          open={this.state.sentModal}
          onClose={this.handleConfirmationClose}
        >
          <Modal.Content>
            <Message info>
              <Message.Header>Emails Sent</Message.Header>
            </Message>
            <Button content="ok" onClick={this.handleConfirmationClose} />
          </Modal.Content>
        </Modal>
        <RuvixxForm
          ready={
            hasFromUserAddress &&
            !!template.id &&
            (this.props.forEntity || value.length > 0) &&
            !!senderName &&
            !!provider &&
            (!!campaign || !!this.props.campaignId)
          }
          onSubmit={this.handleSubmit}
          onSuccess={!errorMessage ? this.props.onSuccess : () => {}}
          successHeader="Email Sent"
          confirmSubmit={true}
          confirmText="Are you sure you want to send this email?"
          ignoreEnterKey={true}
          error={!!errorMessage}
          errorMessage={errorMessage}
        >
          <Form.Group widths="equal">
            <Form.Select
              required
              label="Email Provider"
              options={providerOptions}
              placeholder="Select an Email Provider"
              onChange={this.handleProviderChange}
              value={provider}
              disabled={onlySmtp}
            />
            {!this.props.campaignId && (
              <Form.Select
                required
                label="Campaign"
                options={campaignOptions}
                placeholder="Select a Campaign"
                onChange={this.handleCampaignChange}
                value={campaign}
                disabled={onlySmtp}
              />
            )}
          </Form.Group>
          {!this.props.forEntity && (
            <Form.Select
              required
              label="Recipients"
              clearable
              multiple
              search
              onChange={this.handleSelect}
              options={recipients}
              value={value}
              placeholder="Select an email address"
            />
          )}
          {this.props.checkedArr.length > 0 && (
            <Form.Field inline>
              <label onClick={() => this.toggleIncludeAlternativeEmails()}>
                Include Alternative Emails
              </label>
              <Checkbox
                toggle
                checked={includeAlternativeEmails}
                onChange={() => this.toggleIncludeAlternativeEmails()}
              />
            </Form.Field>
          )}

          <Form.Group widths={"equal"}>
            <Form.Input
              required
              label="Sender Name"
              placeholder="Type in Sender Name"
              onChange={this.handleChange}
              name="senderName"
              value={senderName}
            />
            {usernameField}
          </Form.Group>

          <Accordion active={ccMenuOpen} as={Form.Field}>
            <Accordion.Title
              active={ccMenuOpen}
              index={0}
              onClick={() => this.setState({ ccMenuOpen: !ccMenuOpen })}
            >
              <Icon name="dropdown" />
              CC/BCC Options
            </Accordion.Title>
            <Accordion.Content active={ccMenuOpen}>
              <Form.Group>
                <Form.Checkbox
                  toggle
                  name="ccAccountManagers"
                  checked={ccAccountManagers}
                  onChange={this.handleChange}
                  label="CC Account Managers"
                />
                <Form.Checkbox
                  toggle
                  name="ccExternalAccountManagers"
                  checked={ccExternalAccountManagers}
                  onChange={this.handleChange}
                  label="CC External Account Managers"
                />
              </Form.Group>
              <Form.Input
                label="CC"
                placeholder="email1@test.com,email2@test.com"
                onChange={this.handleChange}
                name="cc"
                value={cc}
              />
              <Form.Input
                label="BCC"
                placeholder="email1@test.com,email2@test.com"
                onChange={this.handleChange}
                name="bcc"
                value={bcc}
              />
            </Accordion.Content>
          </Accordion>
          <Form.Group widths={"equal"}>
            <Form.Select
              search
              required
              label="Email Template"
              options={templateOptions}
              value={template.id}
              placeholder="Select a Template"
              onChange={this.handleTemplateChange}
            />
            <Form.Select
              clearable
              label="Email Footer"
              placeholder="Select an Email Footer"
              onChange={this.handleFooterChange}
              options={this.state.footerOptions}
            />
            <Form.Select
              clearable
              label="Email Signature"
              placeholder="Select an Email Signature"
              onChange={this.handleSignatureChange}
              options={this.state.signatureOptions}
            />
          </Form.Group>
          <Form.Field>
            <Button as="label" className="fileButton">
              <Icon name="plus" />
              Upload File
              <input
                ref={ref => (this.uploadInput = ref)}
                type="file"
                hidden
                multiple
                onChange={this.handleFileChange}
              />
            </Button>
            <UploadProgressRing
              uploads={files}
              onDelete={this.uploadManager.removeUpload}
              xOnComplete={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Subject</label>
            <Segment>{template.subject}</Segment>
          </Form.Field>
          <Form.Field>
            <label>Preview</label>
            <Segment>{ReactHtmlParser(previewHtml)}</Segment>
          </Form.Field>
        </RuvixxForm>
      </>
    );
  }
}

export default NewEmailForm;
