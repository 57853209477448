import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Dropdown,
  Icon,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import campaignConstants from "../../constants/Campaign";
import CONSTANTS from "../../constants/Constants";
import setPageTitle from "../../helpers/title";
import {
  createDateTimeFormatter,
  getPercentage,
} from "./../../components/helpers";
import AuthService from "./../../services/Auth";
import CampaignService from "./../../services/Campaign";
import RegionService from "./../../services/Regions";
import UserService from "./../../services/User";
import "./../../styles/campaign.scss";
import {
  ArchiveCampaignModal as _ArchiveCampaignModal,
  DeleteCampaignModal as _DeleteCampaignModal,
  EditCampaignModal as _EditCampaignModal,
  NewCampaignModal as _NewCampaignModal,
  ActiveInactiveCampaignModal as _ActiveInactiveCampaignModal,
} from "./components/Modals";
import _BatchEditCampaignModal from "./components/BatchEditCampaignModal";

const NewCampaignModal = withRoleCheck(_NewCampaignModal, [
  ACL_RELATIONSHIPS.campaign.create,
  ACL_RELATIONSHIPS.campaignStatusPreset.read,
]);
const EditCampaignModal = withRoleCheck(_EditCampaignModal, [
  ACL_RELATIONSHIPS.campaign.edit,
]);
const ArchiveCampaignModal = withRoleCheck(_ArchiveCampaignModal, [
  ACL_RELATIONSHIPS.campaign.edit,
]);
const ActiveInactiveCampaignModal = withRoleCheck(
  _ActiveInactiveCampaignModal,
  [ACL_RELATIONSHIPS.campaign.edit]
);
const DeleteCampaignModal = withRoleCheck(_DeleteCampaignModal, [
  ACL_RELATIONSHIPS.campaign.delete,
]);
const BatchEditCampaignModal = withRoleCheck(_BatchEditCampaignModal, [
  ACL_RELATIONSHIPS.statusInCampaign.edit,
]);

const formatDate = createDateTimeFormatter({ format: "YYYY-MM-DD" });

class CampaignsTable extends BaseTable {
  constructor(props) {
    super(props);
    this.queryMethod = CampaignService.getCampaignsTable;
    this.state = {
      ...this.state,
      header: "Campaigns",
      headerIcon: "bullhorn",
      tableName: "campaigns",
      noDataText: "No Campaigns found. Try adjusting your filters.",
      createButton: <NewCampaignModal fetchCampaigns={this.fetchData} />,
      campaigns: [],
      regions: [],
      assignees: [],
      enableCustomFieldsSettings: true,
      enableBatchEdit: true,
      exportTableName: "CampaignsTable",
      className: "Campaign",
      defaultQueryParams: {
        status: [CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE],
      },
    };
  }

  deleteCampaign = async id => {
    await CampaignService.deleteCampaign(id);
    this.fetchData();
  };

  fetchCustomFields = async () => {
    const customFields = await CampaignService.getCampaignCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            headerPrefix: "Campaign",
            fields: customFields,
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  async editStatus(campaignId, status) {
    await CampaignService.editStatusInCampaign(campaignId, status);
    this.fetchData();
  }

  activateCampaign = async campaignId => {
    await this.editStatus(campaignId, 1);
  };

  inactivateCampaign = async campaignId => {
    await this.editStatus(campaignId, 2);
  };

  archiveCampaign = async ({ id, status }) => {
    const isArchived = status === CONSTANTS.CAMPAIGN_STATUS_TYPES.ARCHIVED;
    const newStatus = isArchived
      ? CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE
      : CONSTANTS.CAMPAIGN_STATUS_TYPES.ARCHIVED;
    await this.editStatus(id, newStatus);
  };

  async componentDidMount() {
    setPageTitle("Campaigns");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchRegions();
      this.fetchAssignees();
      this.fetchCustomFields();
    }
  }

  batchEditModal = () => {
    return (
      <BatchEditCampaignModal
        campaigns={this.state.checkedArr}
        finishBatchEdit={this.finishBatchEdit}
      ></BatchEditCampaignModal>
    );
  };

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, campaign_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={campaign_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        show: false,
        width: 36,
        Cell: props => (
          <Link to={"/campaigns/" + props.value}>{props.value}</Link>
        ),
      },
      {
        Header: "Name & Description",
        accessor: "name",
        Cell: props => (
          <div>
            <Link to={"/campaigns/" + props.original.id}>{props.value}</Link>
            <div>{props.original.info.description}</div>
          </div>
        ),
      },
      {
        Header: "Start Date",
        accessor: "start_time",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return formatDate(value);
          }
          return null;
        },
      },
      {
        Header: "End Date",
        accessor: "end_time",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return formatDate(value);
          }
          return null;
        },
      },
      {
        Header: "# Entities",
        accessor: "metrics.entity_count",
      },
      {
        Header: "# Contacts",
        accessor: "metrics.contact_count",
      },
      {
        Header: "# Callable Contacts",
        accessor: "metrics.callable_contact_count",
      },
      {
        Header: "# Emailable Contacts",
        accessor: "metrics.emailable_contact_count",
      },
      {
        Header: "# Entities Notified",
        accessor: "metrics.unique_entity",
      },
      {
        Header: "Open Rate",
        accessor: "metrics.unique_opened",
        Cell: props => {
          const value = props.value,
            metrics = props.original.metrics;

          if (value !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(value, metrics.delivered)}
              </div>
            );
          }
        },
      },

      {
        Header: "Bounce Rate",
        accessor: "metrics.bounced",
        Cell: props => {
          const value = props.value,
            metrics = props.original.metrics;

          if (value !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(value, metrics.delivered)}
              </div>
            );
          }
        },
      },
      {
        Header: "Clicked Rate",
        accessor: "metrics.unique_clicked",
        Cell: props => {
          const value = props.value,
            metrics = props.original.metrics;
          if (value !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(value, metrics.unique_opened)}
              </div>
            );
          }
        },
      },
      {
        Header: "Response Rate",
        accessor: "metrics.unique_responded",
        Cell: props => {
          const value = props.value,
            metrics = props.original.metrics;
          if (value !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(value, metrics.delivered)}
              </div>
            );
          }
        },
      },
      {
        Header: "Assignee",
        accessor: "assigned_user_full_name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value: status }) =>
          campaignConstants.status.find(({ id }) => id === status).name,
      },
      {
        Header: "Region",
        accessor: "region_name",
      },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        headerClassName: "centered non-sortable",
        className: "action-menu",
        sortable: false,
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCampaignModal
                campaignId={props.original.id}
                fetchCampaigns={this.fetchData}
              />
              <DeleteCampaignModal
                onConfirmDelete={() => this.deleteCampaign(props.original.id)}
                menuTrigger
              />
              <ArchiveCampaignModal
                onConfirmArchive={() => this.archiveCampaign(props.original)}
                archived={
                  props.original.status ===
                  CONSTANTS.CAMPAIGN_STATUS_TYPES.ARCHIVED
                }
                menuTrigger
              />
              <ActiveInactiveCampaignModal
                isInactive={
                  props.original.status ===
                  CONSTANTS.CAMPAIGN_STATUS_TYPES.INACTIVE
                }
                onConfirmActivate={() =>
                  this.activateCampaign(props.original.id)
                }
                onConfirmInactivate={() =>
                  this.inactivateCampaign(props.original.id)
                }
                disabled={
                  props.original.status ===
                  CONSTANTS.CAMPAIGN_STATUS_TYPES.ARCHIVED
                }
                menuTrigger
              />
              <Dropdown.Item as={Link} to={"/campaigns/" + props.original.id}>
                <Icon name="eye" />
                View
              </Dropdown.Item>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    const { regions, assignees, customFields } = this.state;
    const filters = [
      {
        key: "start_date",
        title: "Start Date",
        type: "dateRange",
      },
      {
        key: "end_date",
        title: "End Date",
        type: "dateRange",
      },
      {
        key: "regions",
        title: "Region",
        type: "select",
        data: regions,
      },
      {
        key: "assignees",
        title: "Assignee",
        type: "ruvixxSelect",
        queryFn: UserService.getUsers,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "status",
        title: "Status",
        type: "select",
        data: campaignConstants.status,
      },
      {
        key: "entity_count",
        title: "# Entities",
        type: "range",
      },
      {
        key: "contact_count",
        title: "# Contacts",
        type: "range",
      },
      {
        key: "callable_contact_count",
        title: "# Callable Contacts",
        type: "range",
      },
      {
        key: "unique_entity",
        title: "# Entities Notified",
        type: "range",
      },
      {
        key: "unique_opened",
        title: "Open Rate",
        type: "range",
      },
      {
        key: "bounced",
        title: "Bounce Rate",
        type: "range",
      },
      {
        key: "unique_clicked",
        title: "Clicked Rate",
        type: "range",
      },
      {
        key: "unique_responded",
        title: "Response Rate",
        type: "range",
      },
    ];
    const defaultFilterList = filters.map(f => f.title);
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        if (!defaultFilterList.includes(field)) {
          filters.push({
            key: `search:custom:${field}`,
            title: field,
            type: "input",
          });
        }
      })
    );
    this.setState({ filters });
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(({ id, name }) => ({
      id,
      name,
    }));
    this.setState({ regions }, this.updateFilterOptions);
  };

  fetchAssignees = async () => {
    let assignees = await UserService.getUsers();
    assignees = assignees.map(({ id, full_name }) => ({
      id,
      name: full_name,
    }));
    this.setState({ assignees }, this.updateFilterOptions);
  };

  render() {
    return (
      <Container fluid className="route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CampaignsTable;
