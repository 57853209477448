import { applyFilters, doRequest } from "./helpers";

export default class EmailUrlService {
  static async getEmailUrls(filters) {
    let path = `/email_url/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEmailUrlsTable(filters) {
    let path = `/email_url/table`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getForms() {
    return doRequest("GET", `/email_url/forms`);
  }

  static async getEmailUrl(id) {
    let path = `/email_url/${id}`;
    return doRequest("GET", path);
  }

  static async createEmailUrl(data) {
    return doRequest("POST", "/email_url/", { data });
  }

  static editEmailUrl(id, data) {
    return doRequest("PUT", `/email_url/${id}`, { data });
  }

  static deleteEmailUrl(id) {
    return doRequest("DELETE", `/email_url/${id}`);
  }

  static generateUrl(emailUrlId, campaignId, contactId) {
    let path = `/email_url/${emailUrlId}/generate_url`;
    const filters = {
      campaign_id: campaignId,
      contact_id: contactId,
    };
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static getURLSubmitedForm(
    emailUrlId,
    campaignId,
    contactId,
    campaignTargetFormResponseId
  ) {
    let path = `/email_url/submitted_form`;
    const filters = {
      email_url_id: emailUrlId,
      campaign_id: campaignId,
      contact_id: contactId,
      campaign_target_form_response_id: campaignTargetFormResponseId,
    };
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}
