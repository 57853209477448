import { applyFilters, doRequest } from "./helpers";

export default class EmailSignatureService {
  static async getEmailSignatures(filters = null) {
    let path = `/email/assets/signatures/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEmailSignaturesForFilters() {
    return doRequest("GET", "/email/assets/signatures/for_filters");
  }

  static async getEmailSignature(id) {
    return doRequest("GET", `/email/assets/signatures/${id}`);
  }

  static async editEmailSignature(id, name, html) {
    return doRequest("PUT", `/email/assets/signatures/${id}`, {
      data: {
        name,
        html,
      },
    });
  }
  static async deleteEmailSignature(id) {
    return doRequest("DELETE", `/email/assets/signatures/${id}`);
  }

  static async createEmailSignature(name, html) {
    return doRequest("POST", "/email/assets/signatures/", {
      data: {
        name,
        html,
      },
    });
  }
}
