import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";

import FilePicker from "../../../../components/FilePicker";
import RuvixxForm from "../../../../components/RuvixxForm";
import UploadManager from "../../../../components/UploadManager";
import { sortByKey } from "../../../../components/helpers";
import CampaignService from "../../../../services/Campaign";
import DataJobService from "../../../../services/DataJob";
import UploadProgressBar from "../../components/UploadProgressBar";
import ValidationSummary from "../../components/ValidationSummary";
import { handleDownload } from "../../helpers";
import ImportSummary from "../ImportSummary";
import ValidationOptions from "../ValidationOptions";
import ValidationReport from "../ValidationReport";

import CONSTANTS from "../../../../constants/Constants";
import "./ImportForm.scoped.scss";

const { DATA_JOB_STATUSES } = CONSTANTS;
const timeoutMilis = 2000;

const steps = ["Upload", "Validation", "Import", "Finish"];

const initialState = {
  loading: false,
  dataJobId: null,
  dataJobStatus: null,
  dataJobDescription: "",
  campaignIds: [],
  campaignName: null,
  stage: "init",
  status: null,
  validation: null,
  duplicateCheck: null,
  import: null,
  isModalHidden: false,
  validationFailed: false,
  name: null,
  error: false,
  success: false,
  errorMessage: "",
  files: [],
  shouldReplace: false,
  campaigns: [],
  choosingOptions: true,
  importStep: {},
  validationSummary: {
    max_rows: 0,
    current_row: 0,
    error_count: 0,
    existing_contact_count: 0,
    existing_entity_count: 0,
    has_errors: false,
    new_contact_count: 0,
    new_entity_count: 0,
    rows_with_error_or_warning: 0,
    warning_count: 0,
  },
  validationOptions: {
    contact_dup_name: "warning",
    email_empty: "warning",
    pn_invalid: "warning",
    pn_empty: "warning",
    check_add_and_remove_tags: "warning",
    check_existing_tag: "warning",
    tag_add_remove: "warning",
    pn_dup_db: "warning",
    pn_dup_file: "warning",
    cf_exists: "warning",
    link_clicked: "warning",
    bounce_reason: "warning",
    bounce_description: "warning",
  },
  openValidationOptions: false,
  importedFromInit: false,
  description: "",
};

const ImportProgress = ({ stage, importedFromInit, isExpired }) => {
  const [step, setStep] = useState(0);
  const isError = ["failed", "aborted"].includes(stage);

  useEffect(() => {
    if (stage === "init" || stage === "uploading") {
      setStep(0);
    } else if (stage === "uploaded" || stage === "validating") {
      setStep(1);
    } else if (stage === "validated" || stage === "importing") {
      setStep(2);
    } else if (stage === "imported") {
      setStep(importedFromInit ? 4 : 3);
    }
  }, [stage, importedFromInit]);

  const getIconName = stepIndex => {
    if (isError) {
      return stepIndex > 1 ? "times" : "check";
    }
    return step > stepIndex ? "check" : "";
  };

  const getIconColor = stepIndex => {
    if (isError) {
      return stepIndex > 1 ? "red" : "green";
    }
    return step === stepIndex
      ? isExpired
        ? "grey"
        : "orange"
      : step > stepIndex
      ? "green"
      : "grey";
  };

  return (
    <ol className="ProgressBar">
      {steps.map((stepName, stepIndex) => (
        <li className="ProgressBar-step" key={stepIndex}>
          <Icon.Group className="icon-check" size="large">
            <Icon className="icon-check-bg" name="circle" />
            <Icon
              className="icon-check-fg"
              name={`${getIconName(stepIndex)} circle`}
              color={getIconColor(stepIndex)}
            />
          </Icon.Group>
          <p>{stepName}</p>
        </li>
      ))}
    </ol>
  );
};

const ValidationTemplate = () => {
  const templateName = "Data";

  const getImportTemplate = async () => {
    let guidelines = [];
    let fields = [];
    const rawTemplate = await DataJobService.getImportTemplate();

    const re = /(?<customField>Custom Field).*?(?<required>\(Required\))?:/;
    for (const row in rawTemplate) {
      const description = rawTemplate[row];
      const match = re.exec(description);

      if (description) {
        if (match?.groups.customField) {
          if (match.groups.required) fields.push(row);
        } else {
          fields.push(row);
        }
        guidelines.push([row, description]);
      } else {
        guidelines.push(["", ""]); // add blank line between model types
        guidelines.push([row, description]);
      }
    }

    fields.shift();

    const templateConfig = [
      {
        data: [fields],
        sheetName: `${templateName} Import Template`,
      },
      {
        data: guidelines,
        sheetName: `${templateName} Import Guidelines`,
      },
    ];

    handleDownload(templateConfig, `${templateName} Import Template.xlsx`);
  };

  return (
    <div className="validation-template">
      <Button
        className="template-download"
        onClick={getImportTemplate}
        size="small"
      >
        <Icon name="download" />
        Download Template
      </Button>
    </div>
  );
};

class ImportForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.uploadManager = new UploadManager();
    this.uploadManager.addProgressListener(this.handleProgress);
    this.uploadManager.addSuccessListener(this.handleValidation);
    this.state = initialState;
  }

  fetchCampaigns = async callback => {
    const rest = await CampaignService.getCampaignsForFilters();
    const sortedCampaigns = sortByKey(
      rest.filter(c => c.id > 0),
      "name"
    );
    const noneCampaign = { id: "none", name: "None" };
    sortedCampaigns.unshift(noneCampaign);
    this.setState(
      {
        campaigns: sortedCampaigns,
      },
      callback
    );
  };

  handleDescriptionChange = (_, { value: description }) => {
    if (description.length <= 255) {
      this.setState({ description });
    }
  };

  handleCampaignSelect = (_, { value: campaignIds }) => {
    this.setCampaignIds(campaignIds);
  };

  setCampaignIds = newCampaignIds => {
    const { campaigns, campaignIds } = this.state;
    if (newCampaignIds.includes("none") && !campaignIds.includes("none")) {
      this.setState({ campaignIds: ["none"], campaignName: null });
      return;
    } else if (newCampaignIds.length > 1 && campaignIds.includes("none")) {
      newCampaignIds = newCampaignIds.filter(id => id !== "none");
    }

    const selectedCampaigns = campaigns.filter(c =>
      newCampaignIds.includes(c.id)
    );
    let campaignName = null;
    if (selectedCampaigns.length > 1) {
      campaignName = selectedCampaigns
        .map(campaign => campaign.name)
        .join(", ");
    } else if (selectedCampaigns[0]?.name !== "None") {
      campaignName = selectedCampaigns[0]?.name || null;
    }

    this.setState({
      campaignIds: newCampaignIds,
      campaignName,
    });
  };

  componentDidMount() {
    if (this.props.dataJobId) {
      this.setState({
        dataJobId: this.props.dataJobId,
        choosingOptions: false,
        loading: true,
      });
    }
    this.fetchCampaigns(() => {
      if (this.props.dataJobId) {
        this.monitor(this.props.dataJobId);
      }
    });
  }

  componentWillUnmount() {
    const { stage, files, dataJobId, isModalHidden, loading } = {
      ...this.state,
      ...this.props,
    };
    if (isModalHidden) {
      return;
    } else if (stage === "uploading") {
      files.forEach(this.uploadManager.removeUpload);
    } else if (stage === "init" && dataJobId && !loading) {
      DataJobService.abortImport(dataJobId);
    }
  }

  startUpload = async (files, event, dropped) => {
    if (!files) {
      this.setState({
        success: false,
        name: null,
        error: false,
        errorMessage: null,
      });
      return;
    }
    if (files.length === 0) {
      this.setState({
        success: false,
        name: null,
        error: true,
        errorMessage: "No file was selected.",
      });
      return;
    }
    if (files.length > 1) {
      this.setState({
        success: false,
        name: null,
        error: true,
        errorMessage: "More than file selected",
      });
      return;
    }
    const file = files[0];
    let errorMessage = null;
    let isFileValid = true;
    if (dropped) {
      const validTypes = ["officedocument.spreadsheetml", "ms-excel"];
      isFileValid = !!file && validTypes.some(type => file.type.includes(type));
      errorMessage = !isFileValid
        ? `File must be a xlsx or xls for validation.`
        : null;
    }

    this.setState({
      isFileValid: true,
      success: false,
      name: file.name,
      error: !isFileValid,
      errorMessage,
    });

    if (isFileValid) {
      this.setState({
        stage: "uploading",
      });
      try {
        await this.uploadManager.uploadFiles(files);
      } catch (error) {
        this.setState({
          stage: "failed",
          error: true,
          errorMessage: "message" in error ? error.message : error.toString(),
        });
      }
    }
  };

  handleProgress = files => {
    this.setState({
      files: [...files], // careful, not enough to be considered immutable...
    });
  };

  handleValidation = async file => {
    this.setState({
      stage: "uploading",
    });
    try {
      this.setState({ stage: "uploaded", file });
    } catch (error) {
      this.setState({
        stage: "init",
        validationFailed: true,
        success: false,
        error: true,
        errorMessage: "message" in error ? error.message : error.toString(),
      });
      return;
    }
  };

  startValidation = async () => {
    const { validationOptions, file } = this.state;
    let { campaignIds, description } = this.state;
    campaignIds = campaignIds === "none" ? [] : campaignIds;

    this.setState({
      stage: "validating",
      choosingOptions: false,
    });
    // Kick off import job
    const dataJobId = (
      await DataJobService.createValidationJob(
        campaignIds.filter(id => id !== "none"),
        [file.attachment],
        validationOptions,
        description
      )
    ).id;

    this.setState({
      dataJobId,
    });
    this.monitor(dataJobId);
  };

  goBackToInitialState = async () => {
    this.uploadManager.removeUploads();
    await DataJobService.deleteJob(this.state.dataJobId);
    const fetchedCampaigns = [...this.state.campaigns];
    this.setState({ ...initialState, campaigns: fetchedCampaigns });
  };

  startImport = async () => {
    const { dataJobId, shouldReplace } = this.state;
    this.setState({
      stage: "importing",
    });
    await DataJobService.confirmImport(dataJobId, true, shouldReplace);
    this.monitor(dataJobId);
  };

  monitor = async jobId => {
    const stage = this.state.stage;

    try {
      const dataJob = await DataJobService.getDataJob(jobId);
      const { info, status, campaign_ids: campaignIds, description } = dataJob;

      this.setState({
        dataJobStatus: status,
        dataJobDescription: description,
        loading: false,
      });

      this.setCampaignIds(campaignIds);
      if (info.aborted) {
        this.setValidationSummary(info);
        this.setState({
          stage: "aborted",
        });
        return;
      }

      if (info && info.hasOwnProperty("is_import")) {
        if (
          info.import &&
          info.import.hasOwnProperty("is_error") &&
          info.import.is_error
        ) {
          this.setState({
            stage: "failed",
            success: false,
            error: true,
            errorMessage: info.import.error_msg,
          });
          this.setValidationSummary(info);
        } else {
          const status = info.log_status;
          const validation = info.validation;
          const importStep = info.import;
          const shouldReplace = info.replace_existing;

          this.setState({
            status,
            validation,
            importStep,
            shouldReplace,
          });

          if (status === "import complete") {
            this.setValidationSummary(info);
            this.setState({
              stage: "imported",
              importedFromInit: stage === "init",
            });
            return;
          }
          let doTimeout = false;

          if (status === "finalizing import") {
            // Import stage
            this.setState({
              stage: "importing",
            });
            doTimeout = true;
          } else {
            // Validation stage
            if (validation) {
              this.setValidationSummary(info);
            } else {
              doTimeout = true;
            }

            if (status === "validating") {
              this.setState({
                stage: "validating",
              });
              doTimeout = true;
            }
            if (status === "validation complete") {
              this.setState({
                stage: "validated",
              });
              return;
            }
            if (status !== "preflight analisis complete") {
              doTimeout = true;
            } else {
              this.setState({
                stage: "validated",
              });
              return;
            }
          }
          if (doTimeout) {
            setTimeout(() => {
              this.monitor(jobId);
            }, timeoutMilis);
          }
        }
      }
    } catch (error) {
      this.setState({
        stage: "init",
        validationFailed: true,
        success: false,
        error: true,
        errorMessage: "message" in error ? error.message : error.toString(),
      });
    }
  };

  setValidationSummary = info => {
    const {
      max_rows,
      current_row,
      error_count,
      existing_contact_count,
      existing_entity_count,
      has_errors,
      new_contact_count,
      new_entity_count,
      rows_with_error_or_warning,
      warning_count,
    } = info.validation;

    this.setState({
      validationSummary: {
        max_rows,
        current_row,
        error_count,
        existing_contact_count,
        existing_entity_count,
        has_errors,
        new_contact_count,
        new_entity_count,
        rows_with_error_or_warning,
        warning_count,
      },
    });
  };

  handleCancelRequest = () => {
    if (
      ["uploading", "validating", "validated", "importing"].includes(
        this.state.stage
      )
    ) {
      this.setState({ cancelRequest: true });
    } else {
      this.props.refreshTable();
      this.props.onClose();
    }
  };

  handleContinue = () => {
    this.setState({ cancelRequest: false });
  };

  handleAbort = () => {
    const { dataJobId } = this.state;

    if (dataJobId) {
      DataJobService.abortImport(dataJobId);
    }

    this.props.refreshTable();
    this.props.onClose();
  };

  handleHide = () => {
    this.setState(
      {
        isModalHidden: true,
      },
      () => {
        this.props.refreshTable();
        this.props.onClose();
      }
    );
  };

  handleToggle = (event, data) => {
    const options = this.state.validationOptions;
    options[data.name] = data.value;
    this.setState({
      validationOptions: options,
    });
  };

  useDefault = () => {
    this.setState({
      validationOptions: {
        contact_dup_name: "warning",
        email_empty: "warning",
        pn_invalid: "warning",
        pn_empty: "warning",
        pn_dup_db: "warning",
        pn_dup_file: "warning",
        cf_exists: "warning",
        link_clicked: "warning",
        bounce_reason: "warning",
        bounce_description: "warning",
      },
    });
  };

  handleChangeShouldReplace = (_, { checked }) => {
    this.setState({
      shouldReplace: checked,
    });
  };

  handleToggleValidationOptions = () => {
    this.setState(({ openValidationOptions }) => ({
      openValidationOptions: !openValidationOptions,
    }));
  };

  render() {
    const {
      dataJobId,
      dataJobStatus,
      dataJobDescription,
      campaignIds,
      campaigns,
      stage,
      error,
      success,
      errorMessage,
      successMessage,
      files,
      cancelRequest,
      validationOptions,
      choosingOptions,
      validationSummary,
      shouldReplace,
      openValidationOptions,
      importStep,
      campaignName,
      importedFromInit,
      description,
      loading,
    } = this.state;
    const validationErrorsFound =
      validationSummary.rows_with_error_or_warning > 0;
    const isFinished = ["imported", "failed", "aborted"].includes(stage);
    const isExpired = dataJobStatus === DATA_JOB_STATUSES.EXPIRED;

    if (loading) {
      return (
        <Icon
          loading
          name="circle notched"
          size="big"
          className="centered"
          color="grey"
        />
      );
    }

    return (
      <RuvixxForm
        ready
        error={error}
        errorMessage={errorMessage}
        success={success}
        successMessage={successMessage}
        extraButtons={[
          !isFinished && !isExpired && (
            <Button
              secondary
              basic
              onClick={this.handleCancelRequest}
              compact
              size="tiny"
            >
              Cancel
            </Button>
          ),
          <Button secondary onClick={this.handleHide} compact size="tiny">
            {isExpired ? "Close" : isFinished ? "Finish" : "Hide Window"}
          </Button>,
        ]}
      >
        <ImportProgress
          stage={stage}
          importedFromInit={importedFromInit}
          isExpired={isExpired}
        />
        {choosingOptions && (
          <div className="validation-grid">
            {stage === "uploading" ? <p>Uploading file...</p> : null}
            {stage === "uploaded" ? (
              <div className="validation-grid">
                <Icon name="check circle" color="green" />
                <p className="successful">Upload successful!</p>
              </div>
            ) : null}
            <UploadProgressBar uploads={files} validation />
            {stage !== "uploaded" && (
              <>
                <FilePicker
                  onSelect={this.startUpload}
                  accept={
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                  primary
                />
                {["init", "uploaded"].includes(stage) && (
                  <p
                    className="validation-msg"
                    style={{ marginBottom: "0.5em" }}
                  >
                    Before you upload your files, make sure it's in Pleteo
                    format
                  </p>
                )}
                <ValidationTemplate />
              </>
            )}
            <Form.Select
              search
              clearable
              inline
              disabled={!!dataJobId}
              label="Choose a Campaign"
              style={{ marginTop: "1em" }}
              options={campaigns.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              }))}
              multiple
              onChange={this.handleCampaignSelect}
              value={campaignIds}
              placeholder="Choose a Campaign"
            />

            {
              <div className="description-container">
                <Form.TextArea
                  required
                  inline
                  label="Description"
                  style={{
                    maxWidth: "calc(100% - 135px)",
                    minWidth: "300px",
                    height: "43px",
                  }}
                  onChange={this.handleDescriptionChange}
                  value={description}
                  placeholder="Description..."
                />
                <span className="description-counter">
                  {description.length}/255
                </span>
              </div>
            }

            <Button
              style={{ marginTop: "1em" }}
              disabled={
                stage !== "uploaded" || !campaignIds.length || !description
              }
              className="item-adder"
              content="Start Validation"
              onClick={this.startValidation}
            />

            <ValidationOptions
              options={validationOptions}
              useDefault={this.useDefault}
              handleToggle={this.handleToggle}
            />
          </div>
        )}
        {["validating", "validated", "failed", "aborted"].includes(stage) && (
          <div>
            <ValidationSummary
              stage={stage}
              summary={validationSummary}
              isExpired={isExpired}
              files={files}
            />
          </div>
        )}
        {["importing", "imported"].includes(stage) && (
          <ImportSummary
            stage={stage}
            validationSummary={validationSummary}
            summary={importStep}
            campaignName={campaignName}
            files={files}
          />
        )}
        {[
          "validating",
          "validated",
          "failed",
          "aborted",
          "init",
          "imported",
        ].includes(stage) &&
          dataJobDescription && (
            <div className="summary-description">
              <p>Description</p>
              <span>{dataJobDescription}</span>
            </div>
          )}

        {validationErrorsFound && (
          <div className="summary-errors">
            <p>The analysis shows errors and / or warnings have been found</p>
            <ValidationReport dataJobId={dataJobId} />
          </div>
        )}
        {[
          "validating",
          "validated",
          "failed",
          "importing",
          "imported",
        ].includes(stage) && (
          <Accordion className="validation-grid">
            <Accordion.Title
              active={openValidationOptions}
              onClick={this.handleToggleValidationOptions}
            >
              <Icon name="dropdown" />
              Validation Options
            </Accordion.Title>
            <Accordion.Content active={openValidationOptions}>
              <ValidationOptions options={validationOptions} finished />
            </Accordion.Content>
          </Accordion>
        )}
        {["validated", "importing", "imported"].includes(stage) && (
          <div className="import-options">
            <Checkbox
              toggle
              checked={shouldReplace}
              onChange={this.handleChangeShouldReplace}
              className="small"
              label="Replace existing entries"
              disabled={stage !== "validated"}
            />
          </div>
        )}
        {stage === "validated" && (
          <div className="validation-actions">
            <Button
              basic
              secondary
              icon
              size="tiny"
              onClick={this.goBackToInitialState}
            >
              <Icon name="undo alternate" />
              <span>Start Over Validation</span>
            </Button>
            {stage === "validated" && (
              <Popup
                disabled={!isExpired}
                content="Data Job has expired"
                position="top center"
                on="hover"
                trigger={
                  <div style={{ display: "inline-block" }}>
                    <Button
                      secondary
                      icon
                      size="tiny"
                      onClick={this.startImport}
                      disabled={isExpired}
                    >
                      <Icon name="right arrow" />
                      <span>Continue to Import</span>
                    </Button>
                  </div>
                }
              ></Popup>
            )}
          </div>
        )}
        {cancelRequest ? (
          <Modal open={true} onClose={this.handleContinue} size="small">
            <Header icon="warning sign" content="Abort Process?" />
            <Modal.Content>
              This operation will abort the process and you will have to start
              again.
            </Modal.Content>
            <Modal.Actions>
              <Button className="warning cancel" onClick={this.handleContinue}>
                <Icon name="remove" /> No
              </Button>
              <Button
                className="warning confirm"
                inverted
                onClick={this.handleAbort}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null}
      </RuvixxForm>
    );
  }
}

export default ImportForm;
