import React from "react";
import moment from "moment";
import {
  Checkbox,
  Dropdown,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import AuthService from "./../../services/Auth";
import EmailTemplateService from "../../services/EmailTemplate";
import {
  CloneTemplateModal as _CloneTemplateModal,
  DeleteTemplateModal as _DeleteTemplateModal,
  EditEmailTemplateModal as _EditEmailTemplateModal,
  NewEmailTemplateModal as _NewEmailTemplateModal,
} from "./components/Modals";
import BaseTable from "../../components/BaseTable";

import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import "../../styles/table.scss";
import CustomDropdown from "../../components/CustomDropdown";
import { checkIsAuthorized } from "../../components/helpers";
import TagList from "../../components/TagList";
import setPageTitle from "../../helpers/title";

const NewEmailTemplateModal = withRoleCheck(_NewEmailTemplateModal, [
  ACL_RELATIONSHIPS.emailTemplatesTags.read,
  ACL_RELATIONSHIPS.emailTemplates.create,
]);
const CloneTemplateModal = withRoleCheck(_CloneTemplateModal, [
  ACL_RELATIONSHIPS.emailTemplatesTags.read,
  ACL_RELATIONSHIPS.emailTemplates.create,
]);
const EditEmailTemplateModal = withRoleCheck(_EditEmailTemplateModal, [
  ACL_RELATIONSHIPS.emailTemplatesTags.read,
  ACL_RELATIONSHIPS.emailTemplates.edit,
]);
const DeleteTemplateModal = withRoleCheck(_DeleteTemplateModal, [
  ACL_RELATIONSHIPS.emailTemplatesTags.read,
  ACL_RELATIONSHIPS.emailTemplates.delete,
]);

class EmailTemplateTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = EmailTemplateService.getEmailTemplatesTable;

    this.state = {
      ...this.state,
      header: "Email Templates",
      headerIcon: "mail outline",
      className: "EmailTemplate",
      tableName: "email_templates",
      noDataText: "No Email Templates found. Create one.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.emailTemplatesTags.create,
        ACL_RELATIONSHIPS.emailTemplatesTags.read,
        ACL_RELATIONSHIPS.emailTemplatesTags.delete,
      ]),
      options: [],
      createButton: (
        <NewEmailTemplateModal
          fetchTemplates={this.fetchData}
          mergeTags={{ mergeTags: [] }}
        />
      ),
      enableCustomFieldsSettings: true,
    };
  }

  async componentDidMount() {
    setPageTitle("Email Templates");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
      await this.fetchTags();
      await this.fetchCustomFields();
      let options = await EmailTemplateService.getTags();
      this.setState({
        createButton: (
          <NewEmailTemplateModal
            fetchTemplates={this.fetchData}
            mergeTags={{ mergeTags: options }}
          />
        ),
        options: options,
      });
    }
  }

  fetchCustomFields = async () => {
    const customFields = await EmailTemplateService.getCustomFields();
    this.setState(
      {
        customFields: [
          { accessorPrefix: "custom_fields", fields: customFields },
        ],
      },
      this.updateFilterOptions
    );
  };

  deleteEmailTemplate = async id => {
    let message = {
      error: false,
      success: false,
      messageContent: "",
      hidden: true,
    };
    try {
      await EmailTemplateService.deleteEmailTemplate(id);
      message.success = true;
      message.hidden = false;
    } catch (error) {
      message.messageContent = error.message;
      message.error = true;
      message.hidden = false;
    }
    this.setState(message);
    this.fetchData();
    this.setState({ hidden: true });
  };

  setColumns = () => {
    let templateColumns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => (
          <Link
            to={{
              pathname: "/settings/email_templates/" + props.original.id,
              state: {
                options: { mergeTags: this.state.options },
              },
            }}
          >
            {props.original.info.image_swap_processing
              ? `${props.value} (Processing)`
              : props.original.info.image_swap_error
              ? `${props.value} (Image Swap Error)`
              : props.value}
          </Link>
        ),
      },
      {
        Header: "Subject",
        accessor: "subject",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD HH:mm");
          }
          return null;
        },
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered",
        className: "centered",
        width: 100,
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditEmailTemplateModal id={id} fetchTemplates={this.fetchData} />
              <DeleteTemplateModal
                menuTrigger
                onConfirmDelete={() => this.deleteEmailTemplate(id)}
                templateId={id}
              />
              <CloneTemplateModal
                emailTemplateId={id}
                fetchTemplates={this.fetchData}
                options={{ mergeTags: this.state.options }}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(templateColumns);
  };

  updateFilterOptions = async () => {
    const { tags, customFields } = this.state;

    let filters = [
      {
        key: "created_at",
        title: "Created At",
        type: "dateRange",
      },
      {
        key: "template_id",
        title: "Name",
        type: "ruvixxSelect",
        queryFn: EmailTemplateService.getEmailTemplatesForFilters,
      },
      {
        key: "search:subject",
        title: "Subject",
        type: "input",
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${field}`,
          title: field,
          type: "input",
        });
      })
    );
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default EmailTemplateTable;
