import { applyFilters, doRequest } from "./helpers";
import CONSTANTS from "../constants/Constants";

export default class CampaignService {
  static async getCampaignsTable(filters = null) {
    let path = "/campaign/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCampaignsForFilters(filters = null) {
    let path = "/campaign/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCampaign(id) {
    return doRequest("GET", `/campaign/${id}`);
  }

  static async getCampaignEmails(id, filters = null) {
    let path = `/campaign/${id}/email`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createCampaign({
    name,
    status,
    assigned_user_id,
    start_time,
    end_time,
    description,
    region,
    form,
    emailService,
    emailType,
    custom,
    campaignStatusPreset,
    caseCampaignStatusPreset,
    emailTemplates,
    revenueStatusPreset,
  }) {
    return doRequest("POST", "/campaign/", {
      data: {
        name: name,
        status,
        assigned_user_id: assigned_user_id,
        start_time: start_time,
        end_time: end_time,
        region_id: region || undefined,
        form_id: form || undefined,
        email_service: emailService,
        email_type: emailType,
        info: {
          email_templates: emailTemplates,
          description: description,
          campaign_status_preset: campaignStatusPreset,
          case_campaign_status_preset: caseCampaignStatusPreset,
          revenue_status_preset: revenueStatusPreset,
        },
        custom_fields: custom,
      },
    });
  }

  static async deleteCampaign(id) {
    return doRequest("DELETE", `/campaign/${id}`);
  }

  static async editCampaign(data) {
    return doRequest("PUT", `/campaign/${data.campaignId}`, {
      data: {
        name: data.name,
        status: data.status,
        assigned_user_id: data.assigned,
        start_time: data.start,
        end_time: data.end,
        region_id: data.region,
        form_id: data.form,
        email_service: data.emailService,
        email_type: data.emailType,
        info: {
          email_templates: data.emailTemplates,
          description: data.description,
          campaign_status_preset: data.campaignStatusPreset,
          case_campaign_status_preset: data.caseCampaignStatusPreset,
          revenue_status_preset: data.revenueStatusPreset,
        },
        custom_fields: data.custom,
      },
    });
  }

  static async editStatusInCampaign(campaignId, statusId) {
    return doRequest("PUT", `/campaign/${campaignId}/status/${statusId}`);
  }

  static async getCampaignTarget(campaign_id, entity_id) {
    return doRequest("GET", `/campaign/${campaign_id}/target/${entity_id}`);
  }

  static async getCampaignTargets(campaign_id, filters = null) {
    let path = `/campaign/${campaign_id}/target/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCampaignTargetsCustomFields(campaign_id) {
    let path = `/campaign/${campaign_id}/target/custom_fields`;
    return doRequest("GET", path);
  }

  static async getCampaignCustomFields() {
    let path = `/campaign/custom_fields`;
    return doRequest("GET", path);
  }

  static async getTargettedEntitiesCustomFields(campaign_id) {
    let path = `/campaign/${campaign_id}/target/entities_custom_fields`;
    return doRequest("GET", path);
  }

  static async createCampaignTarget(
    campaign_id,
    entity_id,
    assigned_email = null,
    custom = {}
  ) {
    return doRequest("POST", `/campaign/${campaign_id}/target/`, {
      data: {
        campaign_id: campaign_id,
        entity_id: entity_id,
        assigned_email: assigned_email,
        custom_fields: custom,
      },
    });
  }

  static async editCampaignTarget({
    campaign_id,
    entity_id,
    assigned_email,
    custom,
    converted,
  }) {
    return doRequest("PUT", `/campaign/${campaign_id}/target/${entity_id}`, {
      data: {
        assigned_email,
        converted,
        custom_fields: custom,
      },
    });
  }

  static async deleteCampaignTarget(campaign_id, entity_id) {
    return doRequest("DELETE", `/campaign/${campaign_id}/target/${entity_id}`);
  }

  static async deleteCampaignTargets(campaign_id, entity_ids) {
    return doRequest("POST", `/campaign/${campaign_id}/target/delete`, {
      data: { entity_ids },
    });
  }

  static async sendEmail({
    campaignId,
    targetId,
    caseId,
    contacts,
    template,
    attachments,
    senderName,
    cc,
    bcc,
    ccAccountManagers,
    ccExternalAccountManagers,
    fromUser,
    smtp_config_id,
    revenueOpportunityId,
    provider,
    footerId,
    signatureId,
    emailAddressIds,
    follow_up,
    is_manual,
    subject,
    eml_file,
    date,
    comments,
  }) {
    const data = {
      campaign_id: campaignId,
      entity_id: targetId,
      revenue_opportunity_id: revenueOpportunityId,
      case_id: caseId,
      contact_ids: contacts,
      status: CONSTANTS.EMAIL_STATUS.DELIVERED,
      template_id: template,
      sender_name: senderName,
      cc,
      bcc,
      cc_account_managers: ccAccountManagers,
      cc_external_account_managers: ccExternalAccountManagers,
      attachments,
      eml_file: eml_file,
      from_user: fromUser,
      provider,
      footer_id: footerId || null,
      signature_id: signatureId,
      ...(smtp_config_id && { smtp_config_id }),
      email_address_ids: emailAddressIds,
      follow_up: follow_up || false,
      is_manual,
      subject,
      date,
      comments,
    };
    return doRequest(
      "POST",
      `/campaign/${campaignId}/target/${
        targetId ? `${targetId}/email/` : "email"
      }`,
      { data }
    );
  }

  static async getEmailData(campaignTargetEmailId) {
    return doRequest("GET", `/email/${campaignTargetEmailId}`);
  }

  static async sendEmailByCampaign({
    campaignId,
    template,
    attachments,
    senderName,
    cc,
    bcc,
    ccAccountManagers,
    ccExternalAccountManagers,
    fromUser,
    provider,
    smtp_config_id,
    footerId,
  }) {
    return doRequest("POST", `/campaign/${campaignId}/action/email`, {
      data: {
        template_id: template,
        sender_name: senderName,
        cc,
        bcc,
        cc_account_managers: ccAccountManagers,
        cc_external_account_managers: ccExternalAccountManagers,
        from_user: fromUser,
        attachments,
        provider,
        footer_id: footerId || null,
        ...(smtp_config_id && { smtp_config_id }),
      },
    });
  }

  static async search(query, campaign_id) {
    // TODO: Check how the query is built
    return doRequest("GET", `/campaign/${campaign_id}/contact/search/${query}`);
  }

  static async addContact(campaignId, targetId, contactId) {
    return doRequest(
      "POST",
      `/campaign/${campaignId}/target/${targetId}/contact/${contactId}`
    );
  }

  static async addContacts(campaignId, targetId, contactIds, includeAll) {
    return doRequest(
      "POST",
      `/campaign/${campaignId}/target/${targetId}/contacts`,
      {
        data: {
          contact_ids: contactIds,
          include_all: includeAll,
        },
      }
    );
  }

  static async removeContact(campaignId, targetId, contactId) {
    return doRequest(
      "DELETE",
      `/campaign/${campaignId}/target/${targetId}/contact/${contactId}`
    );
  }

  static async changeStatusForContact(
    campaignId,
    targetId,
    contactId,
    campaignStatusId,
    reason,
    effectiveDate,
    updateEntity = false
  ) {
    return doRequest(
      "PUT",
      `/campaign/${campaignId}/target/${targetId}/change_status/${contactId}`,
      {
        data: {
          campaign_status_id: campaignStatusId,
          update_entity: updateEntity,
          reason,
          effective_date: effectiveDate,
        },
      }
    );
  }

  static async changeStatusForCase(
    campaignId,
    caseId,
    campaignStatusId,
    effectiveDate,
    reason
  ) {
    return doRequest("PUT", `/campaign_cases/campaign_status`, {
      data: {
        case_ids: [caseId],
        campaign_id: campaignId,
        campaign_status_id: campaignStatusId,
        effective_date: effectiveDate,
        reason,
      },
    });
  }

  static async assignCampaignTarget(campaignId, data) {
    return doRequest("POST", `/campaign/${campaignId}/target/assign/`, {
      data,
    });
  }

  static async changeStatusForCampaignTargets({
    campaignId,
    entityIds,
    campaignStatusId,
    caseCampaignStatusId,
    reason,
    updateContacts = false,
    effectiveDate,
  }) {
    return doRequest("POST", `/campaign/${campaignId}/target/change_status/`, {
      data: {
        campaign_status_id: campaignStatusId,
        case_campaign_status_id: caseCampaignStatusId,
        entity_ids: entityIds,
        update_contacts: updateContacts,
        effective_date: effectiveDate,
        reason,
      },
    });
  }

  static async getCampaignStatuses() {
    return doRequest("GET", "/campaign/statuses");
  }

  static async getCampaignStatusesAsTable(filters = null) {
    let path = "/campaign/statuses/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createCampaignStatus(name) {
    return doRequest("POST", `/campaign/statuses`, {
      data: { name },
    });
  }

  static async editCampaignStatus(id, name) {
    return doRequest("PUT", `/campaign/status/${id}`, {
      data: { name },
    });
  }

  static async deleteCampaignStatus(id) {
    return doRequest("DELETE", `/campaign/status/${id}`);
  }

  static async hasEmailableContacts(id) {
    return doRequest("GET", `/campaign/${id}/has_emailable_contacts`);
  }

  static async getStatusPreset(id, preset_name) {
    return doRequest("GET", `/campaign/${id}/status_preset/${preset_name}`);
  }
}
