import React from "react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";

import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import CallDispositionService from "../../../services/CallDispositions";

import "../../../styles/campaign.scss";
import "../../../styles/table.scss";
import _CallDispositionModal from "./CallDispositionModal";
import _DeleteCallDispositionModal from "./DeleteDispositionModal";

const CallDispositionModal = withRoleCheck(_CallDispositionModal, [
  ACL_RELATIONSHIPS.disposition.create,
]);
const EditCallDispositionModal = withRoleCheck(_CallDispositionModal, [
  ACL_RELATIONSHIPS.disposition.edit,
]);
const DeleteCallDispositionModal = withRoleCheck(_DeleteCallDispositionModal, [
  ACL_RELATIONSHIPS.disposition.delete,
]);

class CallDispositionsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CallDispositionService.getDispositions;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Call Dispositions",
      headerIcon: "phone",
      className: "CallDisposition",
      tableName: "call_dispositions",
      noDataText: "No Call Dispositions found. Create one.",
      createButton: <CallDispositionModal button onSuccess={this.fetchData} />,
      parentDispositions: [],
    };
  }

  async fetchParentDispositions() {
    const params = { has_parent: "false", per_page: 100 };
    let parentDispositions = await CallDispositionService.getDispositions(
      params
    );
    parentDispositions = parentDispositions.data;
    this.setState({ parentDispositions });
  }

  async componentDidMount() {
    setPageTitle("Dispositions");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
      await this.fetchParentDispositions();
      this.setState({
        createButton: (
          <CallDispositionModal
            button
            onSuccess={this.fetchData}
            parentDispositions={this.state.parentDispositions}
            dispositions={this.state.rows}
          />
        ),
      });
    }
  }

  deleteDisposition = async id => {
    await CallDispositionService.deleteDisposition(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Parent",
        accessor: "parent_name",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        id: "dnc",
        Header: "DNC",
        accessor: d => d.dnc.toString(),
      },
      {
        id: "invalid",
        Header: "Invalid",
        accessor: d => d.invalid.toString(),
      },
      {
        id: "is_successful",
        Header: "Is Successful",
        accessor: d => d.is_successful.toString(),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCallDispositionModal
                dispositionId={props.original.id}
                onSuccess={this.fetchData}
                dispositions={this.state.rows}
                parentDispositions={this.state.parentDispositions}
              />
              <DeleteCallDispositionModal
                menuTrigger
                dispositionName={props.original.name}
                dispositionId={props.original.id}
                onConfirmDelete={() =>
                  this.deleteDisposition(props.original.id)
                }
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route" {...this.props}>
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CallDispositionsTable;
