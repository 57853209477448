import { applyFilters, doRequest, downloadFile } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class RevenueOpportunityService {
  static async getRevenueOpportunitiesTable(filters = null) {
    let path = `/revenue_opportunity/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createRevenueOpportunity(data) {
    return doRequest("POST", `/revenue_opportunity/`, {
      data,
    });
  }

  static async editRevenueOpportunity(opportunityId, data) {
    return doRequest("PUT", `/revenue_opportunity/${opportunityId}`, {
      data,
    });
  }

  static async deleteRevenueOpportunity(opportunityId) {
    return doRequest("DELETE", `/revenue_opportunity/${opportunityId}`);
  }

  static async removeRevenueOpportunityAttachment(opportunityId, attachmentId) {
    return doRequest(
      "DELETE",
      `/revenue_opportunity/attachment/${opportunityId}/${attachmentId}`
    );
  }

  static async getAllRevenueOpportunities() {
    const path = `/revenue_opportunity/all`;
    return doRequest("GET", path);
  }

  static async getForFilters(filters = null) {
    let path = "/revenue_opportunity/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getRevenueOpportunityStatusesForFilters() {
    const path = `/revenue_opportunity/statuses/for_filters`;
    return doRequest("GET", path);
  }

  static async getRevenueOpportunityAttachments(opportunityId) {
    return downloadFile(`/revenue_opportunity/${opportunityId}/attachments`);
  }

  static async getRevenueOpportunityStatusesTable(filters = null) {
    let path = `/revenue_opportunity/statuses`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createRevenueOpportunityStatus(data) {
    return doRequest("POST", `/revenue_opportunity/statuses`, {
      data: omitBy(data, isNil),
    });
  }

  static async getRevenueOpportunityStatus(statusId) {
    return doRequest("GET", `/revenue_opportunity/statuses/${statusId}`);
  }

  static async editRevenueOpportunityStatus(statusId, data) {
    return doRequest("PUT", `/revenue_opportunity/statuses/${statusId}`, {
      data: omitBy(data, isNil),
    });
  }

  static async deleteRevenueOpportunityStatus(statusId) {
    return doRequest("DELETE", `/revenue_opportunity/statuses/${statusId}`);
  }

  static async getRevenueOpportunity(campaignId, filters = null) {
    let path = `/revenue_opportunity/campaign/${campaignId}`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getRevenueOpportunityById(revenueOpportunityId) {
    const path = `/revenue_opportunity/${revenueOpportunityId}`;
    return doRequest("GET", path);
  }

  static async setRevenueOpportunityStatus(data) {
    return doRequest("PUT", "/revenue_opportunity/status/", {
      data: omitBy(data, isNil),
    });
  }

  static async getCustomFields() {
    const path = `/revenue_opportunity/custom_fields`;
    return doRequest("GET", path);
  }

  static async calculateLicenseEndDate(data) {
    const path = `/revenue_opportunity/calculate_license_end_date`;
    return doRequest("POST", path, {
      data,
    });
  }
}
