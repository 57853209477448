import { doRequest, applyFilters } from "./helpers";
import store from "store";
import AuthService from "./Auth";

export default class UserService {
  static async search(query) {
    return doRequest("GET", "/user/search/" + query);
  }

  static async getUsersForFilters(filters = null) {
    let path = "/user/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getUserSettings() {
    return doRequest("GET", "/user/settings");
  }

  static async getUserAlerts() {
    return doRequest("GET", "/user/alerts");
  }

  static async editUserSettings(data) {
    let account = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      title: data.title,
      department: data.department,
      mobile_phone: data.mobile,
      time_zone_utc: data.timeZone,
      time_zone_name: data.timeZoneName,
      region_id: data.region,
      preferred_name: data.preferredName,
      attachment: data.attachment,
      old_password: data.oldPassword,
      new_password: data.newPassword,
      phone_numbers: data.phoneNumbers,
    };
    return doRequest("PUT", "/user/settings", {
      data: { settings: { account: account } },
    });
  }

  static async updateUserTableSettings(table_name, setting_name, data) {
    // update store
    let user = store.get("userAuth");
    if (user.table_settings === undefined || user.table_settings == null) {
      user.table_settings = {};
    }
    if (!user.table_settings[table_name]) {
      user.table_settings[table_name] = {};
    }
    if (setting_name == "show_disabled_tags") {
      user.table_settings[setting_name] = data;
    } else {
      user.table_settings[table_name][setting_name] = data;
    }
    store.set("userAuth", user);
    // update DB
    let preferences = { tables: user.table_settings };
    return doRequest("PUT", "/user/settings", {
      data: { settings: { preferences: preferences } },
    });
  }

  static async updateUserPipelineSettings(campaignId, settingName, data) {
    let user = store.get("userAuth");
    if (!user.pipeline_settings) {
      user.pipeline_settings = {};
    }
    if (!user.pipeline_settings[campaignId]) {
      user.pipeline_settings[campaignId] = {};
    }

    user.pipeline_settings[campaignId][settingName] = data;
    store.set("userAuth", user);
    let preferences = { pipelines: user.pipeline_settings };
    return doRequest("PUT", "/user/settings", {
      data: { settings: { preferences: preferences } },
    });
  }

  static async updateUserSidebarSettings(sidebarName, settingName, data) {
    // update store
    let user = store.get("userAuth");
    if (user.sidebar_settings === undefined || user.sidebar_settings === null) {
      user.sidebar_settings = {};
    }
    if (!user.sidebar_settings[sidebarName]) {
      user.sidebar_settings[sidebarName] = {};
    }
    user.sidebar_settings[sidebarName][settingName] = data;
    store.set("userAuth", user);
    // update DB
    let preferences = { sidebars: user.sidebar_settings };
    return doRequest("PUT", "/user/settings", {
      data: { settings: { preferences } },
    });
  }

  static async resetTable(tableName) {
    const user = store.get("userAuth");
    if (user?.table_settings[tableName]) {
      user.table_settings[tableName] = {};
    }

    store.set("userAuth", user);
    const preferences = {
      tables: { ...user.table_settings },
      pipelines: { ...user.pipeline_settings },
      sidebars: { ...user.sidebar_settings },
    };
    preferences.tables[tableName] = {};

    return doRequest("PUT", "/user/settings", {
      data: { settings: { preferences: preferences } },
    });
  }

  static async resetPreferences() {
    // update store
    let user = store.get("userAuth");
    user.table_settings = {
      target_companies: {},
      campaigns: {},
    };
    store.set("userAuth", user);
    // update DB
    let preferences = {
      tables: {
        target_companies: {},
        campaigns: {},
      },
      pipelines: {},
    };
    return doRequest("PUT", "/user/settings", {
      data: { settings: { preferences: preferences } },
    });
  }

  static async getUsers() {
    return doRequest("GET", "/user/");
  }

  static async getCallers(filters = null) {
    let path = "/user/callers";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getUserAuditLogNotificationSettings() {
    return doRequest("GET", "/user/settings/notifications");
  }

  static async getUserAuditLogNotificationSetting(category, action) {
    return doRequest(
      "GET",
      `/user/settings/notifications/${category}/${action}`
    );
  }

  static async editUserAuditLogNotificationSetting(category, action, status) {
    return doRequest(
      "PUT",
      `/user/settings/notifications/${category}/${action}`,
      { data: { status } }
    );
  }

  static async editUserAuditLogNotificationSettings(settingList) {
    return doRequest("PUT", `/user/settings/notifications/`, {
      data: { setting_list: settingList },
    });
  }

  static async isAdmin() {
    if (!AuthService.isLoggedIn()) {
      return false;
    }
    let _isAdmin = false;
    let role = {};
    let permissionCategories = {};
    try {
      const settings = await this.getUserSettings();
      const permissionCategories = await doRequest(
        "GET",
        "/role/permission_categories"
      );
      store.set("permissionCategories", permissionCategories);
      role = settings && settings.role;
      _isAdmin = role && role.id === -1;
    } catch {}
    let user = store.get("userAuth");
    user.role = role;
    store.set("userAuth", user);
    return _isAdmin;
  }

  static async getUsersAsAdmin(filters) {
    let path = `/admin/user`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createUser(data) {
    const {
      email,
      fullName,
      title,
      phoneNumbers,
      role,
      region,
      groupId,
      external,
    } = data;
    return doRequest("POST", "/admin/user", {
      data: {
        email,
        full_name: fullName,
        phone_numbers: phoneNumbers,
        role_id: role,
        title,
        region_id: region,
        external,
        group_id: groupId,
      },
    });
  }

  static async updateUser(userId, data) {
    const {
      email,
      fullName,
      title,
      phoneNumbers,
      role,
      region,
      groupId,
      external,
      rolesOnly,
    } = data;
    return doRequest("PUT", `/admin/user/${userId}`, {
      data: {
        email,
        full_name: fullName,
        phone_numbers: phoneNumbers,
        role_id: role,
        title,
        region_id: region,
        external,
        group_id: groupId,
        roles_only: rolesOnly,
      },
    });
  }

  static async getUserStatus(userId) {
    return doRequest("GET", `/agent_status/${userId}`);
  }

  static async updateUserStatus(userId, data) {
    return doRequest("PUT", `/agent_status/${userId}`, {
      data: {
        ...data,
      },
    });
  }

  static async deleteUser(userId) {
    return doRequest("DELETE", `/admin/user/${userId}`);
  }

  static async resetPassword(userId) {
    return doRequest("POST", `/admin/user/${userId}/reset_password`);
  }
}
