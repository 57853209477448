import { applyFilters, doRequest } from "./helpers";

export default class CampaignTargetCallService {
  static async getTable(filters = null) {
    let path = `/call/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCall(call_id) {
    return doRequest("GET", `/call/${call_id}`);
  }

  static async postCall(data) {
    return doRequest("POST", "/call/", {
      data: {
        attachments: data.attachments,
        campaign_id: data.campaignId,
        contact_id: data.contactId,
        entity_id: data.entityId,
        case_id: data.caseId,
        revenue_opportunity_id: data.revenue_opportunity_id,
        call_direction: data.callDirection,
        call_status: data.callStatus,
        disposition_id: data.dispositionId,
        phone_number: data.phoneNumber,
        recording: data.recording,
        transcript: data.transcript,
        comments: data.comments,
        date: data.date,
      },
    });
  }

  static async editCall(data) {
    return doRequest("PUT", `/call/${data.callId}`, {
      data: {
        disposition_id: data.dispositionId,
        status_verified: data.statusVerified,
        campaign_id: data.campaignId,
        agent_id: data.creatorUserId,
        contact_id: data.contactId,
        entity_id: data.entityId,
        status: data.status,
      },
    });
  }

  static async hangup(callSid, data) {
    return doRequest("POST", `/call/${callSid}/hangup`, { data });
  }

  static async getCallInfo(callSid) {
    let path = `/call/${callSid}`;
    return doRequest("GET", path);
  }
}
